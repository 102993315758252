import React from "react";
import {
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";

const EamilRadioSection = ({ formik, handleRadioChange, employeeEmails }) => {
  return (
    <>
      {/* Keep email access */}
      <Grid item>
        <FormLabel component="legend">What to do with user emails</FormLabel>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="keepEmailAccess"
            name="keepEmailAccess"
            value={formik.values.keepEmailAccess ?? ""}
            onChange={(e) => handleRadioChange("keepAccess")}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Let them keep email access"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* Email active without access */}
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="emailActiveWithOutAccess"
            name="emailActiveWithOutAccess"
            value={formik.values.emailActiveWithOutAccess ?? ""}
            onChange={(e) => handleRadioChange("activeWithOutAccess")}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Leave email active without access until they return to work"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* Forward email following address */}
      <Grid item>
        {/* Forward to following email */}
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="forwadEmailFollowingAddress"
            name="forwadEmailFollowingAddress"
            value={formik.values.forwadEmailFollowingAddress ?? ""}
            onChange={(e) => handleRadioChange("fowardToFollowing")}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Forward to the following employee"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* Auto complete email forward to */}
      {formik.values.forwadEmailFollowingAddress === 1 && (
        <Grid item>
          {/* Auto complete */}
          <Autocomplete
            options={employeeEmails || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, newValue) => {
              formik.setFieldValue("forwardToEmail", newValue?.email || "");
              formik.setFieldValue("forwardToName", newValue?.name);
            }}
            value={
              employeeEmails?.find(
                (option) => option.name === formik.values.forwardToName
              ) || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                name="forwardToName"
                size="small"
                label="Employee"
              />
            )}
          />
        </Grid>
      )}
    </>
  );
};

export default EamilRadioSection;
