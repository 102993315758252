import { api } from "../../utils";
import {
  GET_EMPLOYEE_ANALYTICS,
  GET_EMPLOYEE_ANNIVERSARIES,
  GET_REMOVAL_EMPLOYEES_ANALYTICS,
  GET_TRANSFER_EMPLOYEE_ANALYTICS,
  GET_EMPLOYEE_LEAVES_ANALYTICS,
  GET_EMPLOYEE_RETURN_TO_WORK,
  GET_REPORTS,
  DIRECT_EMPLOYEE_REPORTS,
  GET_HIRE_FIRE_REPORTS,
  GET_DIRECT_HIRE_FIRE_REPORTS,
} from "../ApiConstants";

class DashBoardApi {
  static sharedInstance = new DashBoardApi();

  constructor() {
    if (DashBoardApi.sharedInstance != null) {
      return DashBoardApi.sharedInstance;
    }
  }

  // Get employee dashboard analytics ..
  async getEmployeeAnalytics() {
    try {
      const response = await api.get(GET_EMPLOYEE_ANALYTICS);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get transfer employee dashboard analytics ..
  async getTransferEmployeeAnalytics() {
    try {
      const response = await api.get(GET_TRANSFER_EMPLOYEE_ANALYTICS);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
  // Get employee annivarseries dashboard analytics ..
  async getEmpolyeeAnniversaries() {
    try {
      const response = await api.get(GET_EMPLOYEE_ANNIVERSARIES);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get removal employee dashboard analytics ..
  async getRemovalEmployeeAnalytics() {
    try {
      const response = await api.get(GET_REMOVAL_EMPLOYEES_ANALYTICS);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  //   Get upcoming employees leaves anaylytics  ..
  async getEmployeesLeavesAnalytics() {
    try {
      const response = await api.get(`${GET_EMPLOYEE_LEAVES_ANALYTICS}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  //  Get upcoming employees return to work  ..

  async getEmployeesReturnToWork() {
    try {
      const response = await api.get(`${GET_EMPLOYEE_RETURN_TO_WORK}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get Reports  ..
  async getReports(body) {
    try {
      const response = await api.post(`${GET_REPORTS}`, body);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get hire and fire employee reports (all employees) ..
  async getHireAndFireReport(body) {
    try {
      const response = await api.post(`${GET_HIRE_FIRE_REPORTS}`, body);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get direct employee hire and fire reports
  async getDirectEmployeeHireFireReports(body) {
    try {
      const response = await api.post(`${GET_DIRECT_HIRE_FIRE_REPORTS}`, body);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  async getDirectEmployeeReports(body) {
    try {
      const response = await api.post(`${DIRECT_EMPLOYEE_REPORTS}`, body);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
}

export default DashBoardApi.sharedInstance;
