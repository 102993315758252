import React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { menuOptions } from "../../../../constants/AppConstants";

const ReportForm = (props) => {
  const {
    reportType,
    setReportType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleReset,
  } = props;
  return (
    <Grid container>
      {/* Form  */}
      <Grid item xs={12} md={6}>
        <FormControl>
          <InputLabel id="report-label">Select Report Type</InputLabel>
          <Select
            style={{ width: 270 }}
            id="select"
            label="Select Report Type"
            value={reportType}
            onChange={(event) => {
              setReportType(event.target.value);
            }}
          >
            {menuOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: 1,
        }}
      >
        {/* Start Date */}
        <Grid item xs={12} md={3}>
          {/* Adjusted md value */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              label="Start Date"
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              onBlur={() => {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        {/* End Date */}
        <Grid item xs={12} md={3}>
          {/* Adjusted md value */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => {
                setEndDate(date);
              }}
              onBlur={() => {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        {/* Reset Button */}
        <Grid item xs={12} md={2}>
          <Button variant="contained" color="inherit" onClick={handleReset}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportForm;
