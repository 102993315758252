import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, IconButton, Menu } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReportIcon from "@mui/icons-material/Report";
import SendIcon from "@mui/icons-material/Send";
import EscalatorIcon from "@mui/icons-material/Escalator";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import {
  ContentPaste,
  MoreVert,
  TransferWithinAStation,
} from "@mui/icons-material";
import EmployeeExitFormDialog from "../EmployeeExitForm/EmployeeExitForm";
import EmployeeLeaveForm from "../../../LeaveOfAbsencePage/EmployeeLeaveForm";
import TransferEmployeeDialog from "../TransferEmployeeDialog/TransferEmployeeDialog";
import { Button } from "@mui/material";

const CustomTable = (props) => {
  const { data, title, currentTabValue } = props;

  const navigate = useNavigate();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [employeeID, setEmployeeID] = useState(null);
  const [transferOpen, setTransferOpen] = useState(false);
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = (event, employeeID) => {
    setOpenMenu(event.currentTarget);
    setEmployeeID(employeeID);
  };
  const handleCloseMenu = () => setOpenMenu(null);

  //   Open leave dialog ..
  const handleLeaveNavigate = () => {
    setOpenLeaveDialog(true);
  };

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };
  // Open transfer employee dialog ..
  const handleTransferOpen = () => {
    setTransferOpen(true);
  };

  //   Handle update employee navigate ..
  const handleUpdatNavigate = () => {
    navigate(`/update/${employeeID}`, {
      state: { currentTabValue: currentTabValue },
    });
  };

  const handleCreateNavigate = () => {
    navigate("/create", { state: { currentTabValue: currentTabValue } });
  };

  const columns = [
    {
      name: "ID",
      label: "ID",
      options: {
        filter: false,
        download: false,
        print: false,
        display: "excluded",
        searchable: false,
      },
    },
    {
      name: "email",
      options: {
        // filter: false,
        // download: false,
        // print: false,
        // display: "excluded",
        searchable: true,
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },
    // For Known as ..
    {
      name: "knowsAs",
      label: "Known As",
    },
    {
      name: "dealership",
      label: "Dealership",
    },
    {
      name: "department",
      label: "Department",
    },
    {
      name: "position",
      label: "Position",
    },
    {
      name: "workOfYears",
      label: "Years",
      options: {
        customBodyRender: (value, meta) => {
          if (value === "0 years ") return "less than a year";
          else return value;
        },
      },
    },

    {
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("YYYY-MM-DD");
        },
      },
    },

    // Actions  ...
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log("tableMeta");
          console.log(tableMeta);
          return (
            <>
              <Grid sx={{ display: "flex", flexDirection: "row" }}>
                <IconButton
                  color="primary"
                  fontSize="default"
                  onClick={(e) => handleOpenMenu(e, tableMeta.rowData[0])}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  elevation={2}
                  sx={{ ".MuiPaper-root": { width: 175 } }}
                  closeAfterTransition
                  anchorEl={openMenu}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(openMenu)}
                  onClose={handleCloseMenu}
                  //keepMounted
                >
                  <Button
                    variant="text"
                    startIcon={<ContentPaste />}
                    fullWidth
                    onClick={() => navigate(`/file/${employeeID}`)}
                  >
                    Employee File
                  </Button>
                  <Button
                    fullWidth
                    startIcon={<TransferWithinAStation />}
                    onClick={() => {
                      handleTransferOpen();
                    }}
                  >
                    Transfer
                  </Button>
                  <Button fullWidth onClick={() => handleLeaveNavigate()}>
                    Leave Request
                  </Button>
                  <Button
                    color={"inherit"}
                    fullWidth
                    startIcon={<EditIcon />}
                    variant="text"
                    onClick={() => {
                      handleUpdatNavigate();
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    fullWidth
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      handleConfirmOpen();
                    }}
                  >
                    Remove
                  </Button>
                </Menu>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    // responsive,
    selectableRows: "none",
    // searchAlwaysOpen: true,
  };
  return (
    <>
      <Grid sx={{ marginBottom: 3 }}>
        <Button
          onClick={handleCreateNavigate}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Employee
        </Button>
      </Grid>
      <Grid>
        <MUIDataTable
          sx={{
            "MuiTableCell-root": {
              whiteSpace: "nowrap",
            },
          }}
          title={title}
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
      {/* Employee Exit Form Dialog */}
      <EmployeeExitFormDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        employeeID={employeeID}
        setEmployeeID={setEmployeeID}
      />

      {/* Transfer Employee Dialog */}
      <TransferEmployeeDialog
        open={transferOpen}
        setOpen={setTransferOpen}
        id={employeeID}
        setID={setEmployeeID}
      />

      {/* Employee leave dialog */}
      <EmployeeLeaveForm
        id={employeeID}
        setID={setEmployeeID}
        open={openLeaveDialog}
        setOpen={setOpenLeaveDialog}
      />
    </>
  );
};

export default CustomTable;
