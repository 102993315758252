import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { FormikProvider, useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { exitEmployeeFormValidtionSchema } from "../../../../utils/validations";
import EmployeeApi from "../../../../services/APIs/Employee.Api";
import LocalStorage from "../../../../managers/LocalStorage";

import dynamicStyle from "./styles";

import { styled } from "@mui/system";
import { Navigate, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const initialValues = {
  dealership: "",
  fullName: "",
  email: "",
  dateOfDeparture: null,
  department: "",
  assignCustomersTo: "",
  emailForwardTo: "",
  employee: "",
  reasonAndNotes: "",
};

const EmployeeExitForm = (props) => {
  const { open, setOpen, employeeID, setEmployeeID, removalID, setRemovalID } =
    props;

  const classes = dynamicStyle();

  const user = LocalStorage.getUser();
  const navigate = useNavigate();

  const [employeeDealership, setEmployeeDealership] = useState(null);

  // get single employee ..
  const { data: singleEmployee } = useQuery(
    ["GET_SINGLE_EMPLOYEE", employeeID],
    () => EmployeeApi.getEmployeeByID(employeeID),
    {
      enabled: !!employeeID,
    }
  );

  // get removal by id ..

  const { data: singleRemoval } = useQuery(
    ["GET_SINGLE_REMOVAL", removalID],
    () => EmployeeApi.getRemovalEmployeeByID(removalID),
    {
      enabled: !!removalID,
    }
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: exitEmployeeFormValidtionSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  // Set form fields on create removal employee ..
  useEffect(() => {
    if (singleEmployee != undefined && employeeID != null) {
      formik.setFieldValue("dealership", singleEmployee[0]?.dealership);
      formik.setFieldValue("fullName", singleEmployee[0]?.fullName);
      formik.setFieldValue("department", singleEmployee[0]?.department);
      formik.setFieldValue("email", singleEmployee[0]?.email);
      // Setting employee dealership  ..
      setEmployeeDealership(singleEmployee[0]?.dealership);
    }
  }, [singleEmployee]);

  // Get employee emails and names based on dealership ..
  const { data: employeeEmails } = useQuery(
    ["EMPLOYEE_EMAILS", employeeDealership],
    () =>
      EmployeeApi.getEmployeesEmailsByDealership({
        dealership: employeeDealership,
      }),
    {
      enabled: !!employeeDealership,
    }
  );

  // Set form fields on update removal employee ..

  useEffect(() => {
    if (singleRemoval != undefined && removalID != null) {
      formik.setFieldValue("dealership", singleRemoval[0]?.dealership);
      formik.setFieldValue("fullName", singleRemoval[0]?.fullName);
      formik.setFieldValue("department", singleRemoval[0]?.department);
      formik.setFieldValue("email", singleRemoval[0]?.email);
      formik.setFieldValue(
        "dateOfDeparture",
        dayjs(singleRemoval[0]?.dateOfDeparture ?? {})
      );
      formik.setFieldValue("emailForwardTo", singleRemoval[0]?.emailForwardTo);
      formik.setFieldValue("reasonAndNotes", singleRemoval[0]?.reasonAndNotes);
      formik.setFieldValue("employee", singleRemoval[0]?.employeeStatus);
      formik.setFieldValue(
        "assignCustomersTo",
        singleRemoval[0]?.assignCustomersTo
      );
    }
  }, [singleRemoval]);

  //Create Removal Employee   ...
  const { mutate: Removal, isLoading } = useMutation(
    (body) => {
      if (removalID != null) {
        return EmployeeApi.updateRemovalEmployee(removalID, body);
      } else if (employeeID != null) {
        return EmployeeApi.createEmployeeRemoval(body);
      }
    },
    {
      onSuccess: (res) => {
        if (res.success) {
          toast.success(res.message);
          if (employeeID) {
            setEmployeeID(null);
          }
          if (removalID) {
            setRemovalID(null);
          }
          setOpen(false);
          formik.resetForm();
          navigate("/removals");
        }
      },
      onError: (error) => {
        console.log("method error =", error);
        toast.error(error.message);
      },
    }
  );

  const handleSubmit = (values) => {
    //get value from formik
    var allow = true;

    if (values.emailForwardTo == "")
      var allow = window.confirm(
        "Do you want to continue without an Email Forward to?"
      );
    if (!allow) return;

    Removal(values);
  };

  const handleClose = () => {
    if (employeeID) {
      setEmployeeID(null);
    }
    if (removalID) {
      setRemovalID(null);
    }
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <Grid className={classes.dialogTitle}>
        <Typography sx={{ margin: 2.5, fontSize: 20 }}>
          Employee exit form
        </Typography>

        <CloseIcon
          onClick={handleClose}
          sx={{ margin: 2.5, color: "white", cursor: "pointer" }}
          fontSize="medium"
        />
      </Grid>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={1}>
            {/* Fullname */}
            <Grid item xs={6}>
              <TextField
                type="text"
                margin="dense"
                name="fullName"
                label="Fullname"
                value={formik.values.fullName}
                fullWidth
                readonly="true"
                size="small"
              />
            </Grid>
            {/* Dealership text field */}
            <Grid item xs={6}>
              <TextField
                type="text"
                margin="dense"
                name="dealership"
                label="Dealership"
                value={formik.values.dealership}
                fullWidth
                readonly="true"
                size="small"
              />
            </Grid>
            {/* Department */}
            <Grid item xs={6}>
              <TextField
                type="text"
                margin="dense"
                name="department"
                label="Department"
                value={formik.values.department}
                fullWidth
                readonly="true"
                size="small"
              />
            </Grid>
            {/* Date of departure */}
            <Grid item xs={6} sx={{ marginTop: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%" }}
                  label="Date of Departure"
                  value={formik.values.dateOfDeparture}
                  onChange={(date) =>
                    formik.setFieldValue("dateOfDeparture", date)
                  }
                  onBlur={() => formik.setFieldTouched("dateOfDeparture", true)}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                      error:
                        formik.touched.dateOfDeparture &&
                        Boolean(formik.errors.dateOfDeparture),
                      helperText:
                        formik.touched.dateOfDeparture &&
                        formik.errors.dateOfDeparture,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            {/* Assign customers to  */}
            {formik.values.department === "Sales" && (
              <Grid item xs={6}>
                {/* Auto complete */}
                <Autocomplete
                  options={employeeEmails || []}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "assignCustomersTo",
                      newValue?.name || ""
                    );
                  }}
                  value={
                    employeeEmails?.find(
                      (option) =>
                        option.name === formik.values.assignCustomersTo
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="assignCustomersTo"
                      size="small"
                      label="Assing Customers to"
                    />
                  )}
                />
              </Grid>
            )}
            {/* Email forwared to  */}
            <Grid item xs={6}>
              {/* Auto complete */}
              <Autocomplete
                options={employeeEmails || []}
                getOptionLabel={(option) => option?.name}
                onChange={(event, newValue) => {
                  formik.setFieldValue("emailForwardTo", newValue?.name || "");
                }}
                value={
                  employeeEmails?.find(
                    (option) => option.name === formik.values.emailForwardTo
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="emailForwardTo"
                    size="small"
                    label="Email Forward to"
                    // error={
                    //   formik.touched.emailForwardTo &&
                    //   Boolean(formik.errors.emailForwardTo)
                    // }
                    variant="outlined"
                    // helperText={
                    //   formik.touched.emailForwardTo &&
                    //   formik.errors.emailForwardTo
                    // }
                  />
                )}
              />
            </Grid>

            {/* Employee status for job exit */}
            <Grid item xs={6} sx={{ marginTop: 1 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Employee</FormLabel>
                <RadioGroup
                  row
                  aria-label="employee"
                  name="employee"
                  value={formik.values.employee ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    value="Quit"
                    control={<Radio />}
                    label="Quit"
                  />
                  <FormControlLabel
                    value="Terminated"
                    control={<Radio />}
                    label="Terminated"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* Reason & Notes */}
            <Grid item xs={12}>
              <TextField
                type="text"
                margin="dense"
                name="reasonAndNotes"
                label="Reason & Notes"
                value={formik.values.reasonAndNotes}
                onChange={formik.handleChange}
                fullWidth
                multiline
                rows={3}
                size="small"
                error={
                  formik.touched.reasonAndNotes &&
                  Boolean(formik.errors.reasonAndNotes)
                }
                variant="outlined"
                helperText={
                  formik.touched.reasonAndNotes && formik.errors.reasonAndNotes
                }
              />
            </Grid>
            {/* username */}
            <Grid item xs={6}>
              <TextField
                type="text"
                margin="dense"
                name="userName"
                label="Username"
                value={user?.name}
                fullWidth
                readonly="true"
                size="small"
              />
            </Grid>
            {/* User email */}
            <Grid item xs={6}>
              <TextField
                type="text"
                margin="dense"
                name="userEmail"
                label="Email"
                value={user?.email}
                fullWidth
                readonly="true"
                size="small"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              className={classes.deleteButton}
              style={{ backgroundColor: "#ed342a", color: "white" }}
              onClick={handleClose}
              variant="contained"
            >
              <CloseIcon fontSize="small" className={classes.iconsStyle} />{" "}
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              <SaveIcon fontSize="small" className={classes.iconsStyle} />{" "}
              {employeeID ? "Save" : "Update"}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default EmployeeExitForm;
