import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, List, ListItem, ListItemText } from "@mui/material";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import {
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import { isNull } from "lodash";

import DealerShipApi from "../../services/APIs/DealerShip.Api";
import EmailTemplateApi from "../../services/APIs/EmailTemplate.Api";
import { emailTemplateValidationSchema } from "../../utils/validations";
import { Parameters_List } from "../../constants/AppConstants";
import { ParametersSearchBox } from "./components";

import dynamicStyle from "./styles";

const initialValues = {
  eventType: "",
  dealership: "",
  department: "",
  templateName: "",
  subject: "",
  recipients: [],
  html: "",
};

const EmailTemplateForm = () => {
  const navigate = useNavigate();

  const quillRef = useRef();
  const { id } = useParams();
  const match = useMatch(`/template/update/${id}`);

  const [html, setHtml] = useState("");
  const [dealerships, setDealerShips] = useState([]);
  const [departments, setDepartMents] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [value, setValue] = useState(null);
  const [singleTemplate, setSinlgeTemplate] = useState([]);
  // State for subject text field focuse
  const [isSubjectTxtFocused, setIsSubjectTxtFocused] = useState(false);

  const classes = dynamicStyle();

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  // Get Dealerships ..
  const getDealerships = async () => {
    const response = await DealerShipApi.getDealersShips();
    response.unshift({ ID: 0, name: "All" });
    setDealerShips(response);
  };

  console.log();
  // Get Departments Mehtod   ..
  const getDepartements = async () => {
    const response = await DealerShipApi.getDepartements();
    response.unshift({ ID: 0, name: "All" });
    setDepartMents(response);
  };

  // Get Event Types ..
  const getEventTypes = async () => {
    const response = await EmailTemplateApi.getEvetTypes();
    setEventTypes(response);
  };

  // Get Single Template By ID...
  useEffect(() => {
    if (id !== undefined) {
      const getEmailTemplateByID = async (id) => {
        const response = await EmailTemplateApi.getEmailTemplateByID(id);
        setSinlgeTemplate(response);
      };
      getEmailTemplateByID(id);
    }
  }, [id]);

  // Set form fields if update email template ..
  useEffect(() => {
    if (!isNull(match)) {
      formik.setFieldValue("eventType", singleTemplate[0]?.eventType);
      formik.setFieldValue("templateName", singleTemplate[0]?.templateName);
      formik.setFieldValue("subject", singleTemplate[0]?.subject);
      formik.setFieldValue("recipients", singleTemplate[0]?.recipients);
      formik.setFieldValue("dealership", singleTemplate[0]?.dealership);
      formik.setFieldValue("department", singleTemplate[0]?.department);
      formik.setFieldValue("html", singleTemplate[0]?.html);

      setHtml(singleTemplate[0]?.html);
    }
  }, [singleTemplate]);

  useEffect(() => {
    getDealerships();
    getDepartements();
    getEventTypes();
  }, []);

  // Formik ..
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: emailTemplateValidationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  // Reset Paramaters list on every evnt type select.
  function resetParametersList() {
    Parameters_List.length = 0;
    // Add default parameters
    Parameters_List.push(
      { label: "fullName" },
      { label: "knownAs" },
      { label: "position" },
      { label: "phone" },
      { label: "start date" },
      { label: "birthday" },
      { label: "email" },
      { label: "address" },
      { label: "city" },
      { label: "postalCode" },
      { label: "refered by" },
      { label: "reporting manager" },
      { label: "copy from employee" },
      { label: "additional notes" },
      { label: "share birthday" },
      { label: "gender" },
      { label: "department" },
      { label: "dealership" },
      { label: "employee type" },
      { label: "pronouns" },
      { label: "state" },
      { label: "employeeNumber" },
      { label: "phoneExtension" }
    );
  }

  // Adding more label issue when event type change  ..
  switch (formik.values.eventType) {
    // create new employee
    case "create_employee":
      resetParametersList();
      const create_EmployeeLabels = ["created by", "created by email"];
      create_EmployeeLabels.forEach((label) => {
        const exists = Parameters_List.some((param) => param.label === label);
        if (!exists) {
          Parameters_List.push({ label });
        }
      });
      break;
    // Transfer employee case
    case "transfer_employee":
      resetParametersList();
      const transferLabels = [
        "created by",
        "created by email",
        "current dealership",
        "current department",
        "current position",
        "transfer date",
        "copy permission from existing employee",
      ];

      transferLabels.forEach((label) => {
        const exists = Parameters_List.some((param) => param.label === label);
        if (!exists) {
          Parameters_List.push({ label });
        }
      });
      break;
    // Transfer employee completed case
    case "transfer_employee_completed":
      resetParametersList();
      const transferCompletedLabels = [
        "created by",
        "created by email",
        "current dealership",
        "current department",
        "current position",
        "transfer date",
        "copy permission from existing employee",
      ];

      transferCompletedLabels.forEach((label) => {
        const exists = Parameters_List.some((param) => param.label === label);
        if (!exists) {
          Parameters_List.push({ label });
        }
      });
      break;
    // Create comment case
    case "create_comment":
      resetParametersList();
      const commentLabels = [
        "tag",
        "comment",
        "file name",
        "created date",
        "created by",
      ];

      commentLabels.forEach((label) => {
        const exists = Parameters_List.some((param) => param.label === label);
        if (!exists) {
          Parameters_List.push({ label });
        }
      });
      break;
    // Edit employee case
    case "edit_employee":
      resetParametersList();
      const edit_employeeLables = ["changed values"];

      edit_employeeLables.forEach((label) => {
        const exists = Parameters_List.some((param) => param.label === label);
        if (!exists) {
          Parameters_List.push({ label });
        }
      });
      break;
    //  Removal employee case
    case "removal_employee":
      resetParametersList();
      const removal_employeeLables = [
        "created by",
        "created by email",
        "date of departure",
        "email forward to",
        "reason and notes",
        "employee status",
      ];
      removal_employeeLables.forEach((label) => {
        const exists = Parameters_List.some((param) => param.label === label);
        if (!exists) {
          Parameters_List.push({ label });
        }
      });
      break;
    //  Removal employee completed case
    case "removal_completed":
      resetParametersList();
      const removal_completed_employeeLables = [
        "created by",
        "created by email",
        "date of departure",
        "email forward to",
        "reason and notes",
        "employee status",
      ];

      removal_completed_employeeLables.forEach((label) => {
        const exists = Parameters_List.some((param) => param.label === label);
        if (!exists) {
          Parameters_List.push({ label });
        }
      });
      break;
    // Employee leave case
    case "employee_leave_start":
    case "employee_leave_request":
    case "employee_leave_complete":
      resetParametersList();
      const employe_leaveLabels = [
        "restrict all employee access",
        "let employee keep their existing access",
        "allow CRM access",
        "allow online training",
        "allow inFord or DealerConnect access",
        "allow vAuto Access",
        "let them keep email access",
        "leave email active without access until return to work",
        "created by",
        "created by email",
        "leave type",
        "effective date",
        "return to work date",
        "forward to employee",
      ];

      employe_leaveLabels.forEach((label) => {
        const exists = Parameters_List.some((param) => param.label === label);
        if (!exists) {
          Parameters_List.push({ label });
        }
      });
      break;
    // Default case
    default:
      break;
  }

  const { mutate: Tags, isLoading } = useMutation(
    (body) => {
      if (!isNull(match)) {
        return EmailTemplateApi.updateEmailTemplate(body, id);
      } else {
        return EmailTemplateApi.createEmailTemplate(body);
      }
    },
    {
      onSuccess: (res) => {
        if (res.success) {
          toast.success(res.message);
          navigate("/email-templates");
          formik.resetForm();
        } else {
          toast.error(res.message);
        }
      },
      onError: (error) => toast.error(error.message),
    }
  );
  const handleSubmit = (body) => {
    console.log("body ==", body);
    Tags(body);
  };

  const handleQuillChange = (content) => {
    setHtml(content);
    formik.setFieldValue("html", content);
  };

  // Handle cancel ..
  const handleCancel = () => {
    navigate(-1);
    formik.resetForm();
  };

  console.log("email body :", formik.values.html);
  // Handle list item click  ..
  const handleItemClick = (label) => {
    const subjectTxt = formik?.values?.subject;

    if (isSubjectTxtFocused) {
      const cursorPosition =
        document.getElementsByName("subject")[0]?.selectionStart;

      const updatedSubjectTxt =
        subjectTxt.substring(0, cursorPosition) +
        `<${label}>` +
        subjectTxt.substring(cursorPosition);

      formik.setFieldValue("subject", updatedSubjectTxt);
    } else {
      const quill = quillRef.current.getEditor();
      console.log("quill", quill);
      if (label && quill) {
        const cursorPosition = quill?.selection?.savedRange?.index;
        console.log("Quill Cursor Position:", cursorPosition);
        if (cursorPosition >= 0) {
          quill.insertText(cursorPosition, `<${label}>`);
          const updatedHtml = quill.root.innerHTML;
          setHtml(updatedHtml);
          formik.setFieldValue("html", updatedHtml);
        }
      }
    }

    setValue(label);
  };

  return (
    // Main Grid
    <Grid>
      <Button
        variant="contained"
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      {/* Button Grid */}
      <Grid container spacing={4} justifyContent={"center"} sx={{ my: 1 }}>
        <Grid
          item
          xs={12}
          sx={{ display: { xs: "block", sm: "block", md: "none" } }}
        >
          <ParametersList
            parameters={Parameters_List}
            onItemClick={handleItemClick}
            horizontal={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={7}>
          <Card>
            <Grid container justifyContent={"center"}>
              <Typography
                style={{ color: "grey", fontWeight: "bold", fontSize: 20 }}
              >
                Create Email Template
              </Typography>
            </Grid>
            <CardContent>
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  {/* Event Type autocomplete */}
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={eventTypes || []}
                      getOptionLabel={(option) => option?.eventType}
                      value={
                        eventTypes?.find(
                          (option) =>
                            option.eventType === formik.values.eventType
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setValues({
                            ...formik.values,
                            eventType: newValue.eventType,
                          });
                        } else {
                          formik.setValues({
                            ...formik.values,
                            eventType: "",
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          name="eventType"
                          size="small"
                          label="Event Type"
                          error={
                            formik.touched.eventType &&
                            Boolean(formik.errors.eventType)
                          }
                          helperText={
                            formik.touched.eventType && formik.errors.eventType
                          }
                        />
                      )}
                    />
                  </Grid>
                  {/* Template Name TextField */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="templateName"
                      label="Template Name"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={formik.values.templateName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.templateName &&
                        Boolean(formik.errors.templateName)
                      }
                      helperText={
                        formik.touched.templateName &&
                        formik.errors.templateName
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="recipients"
                      label="Recipients"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={formik.values.recipients}
                      onChange={(event) => {
                        const recipientsArray = event.target.value.split(",");
                        formik.setFieldValue("recipients", recipientsArray);
                      }}
                      error={
                        formik.touched.recipients &&
                        Boolean(formik.errors.recipients)
                      }
                      helperText={
                        (formik.touched.recipients &&
                          formik.errors.recipients) ||
                        "Enter email addresses separated by commas"
                      }
                    />
                  </Grid>
                  {/* Dealership autocomplete */}
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={dealerships || []}
                      getOptionLabel={(option) => option?.name}
                      value={
                        dealerships?.find(
                          (option) => option.name === formik.values.dealership
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setValues({
                            ...formik.values,
                            dealership: newValue.name,
                          });
                        } else {
                          formik.setValues({
                            ...formik.values,
                            dealership: "",
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          name="dealership"
                          size="small"
                          label="Dealership"
                          error={
                            formik.touched.dealership &&
                            Boolean(formik.errors.dealership)
                          }
                          helperText={
                            formik.touched.dealership &&
                            formik.errors.dealership
                          }
                        />
                      )}
                    />
                  </Grid>
                  {/* Departments autcomplete */}
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={departments || []}
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "department",
                          newValue?.name || ""
                        );
                      }}
                      value={
                        departments?.find(
                          (option) => option.name === formik.values.department
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          name="department"
                          size="small"
                          label="Department"
                          error={
                            formik.touched.department &&
                            Boolean(formik.errors.department)
                          }
                          helperText={
                            formik.touched.department &&
                            formik.errors.department
                          }
                        />
                      )}
                    />
                  </Grid>
                  {/* Subject TextField */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="subject"
                      label="Subject"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      rows={2}
                      error={
                        formik.touched.subject && Boolean(formik.errors.subject)
                      }
                      helperText={
                        formik.touched.subject && formik.errors.subject
                      }
                      // On Focus .
                      onFocus={() => setIsSubjectTxtFocused(true)}
                      // On Blur (un focused) .
                    />
                  </Grid>
                  {/* React Quill For HTML */}
                </Grid>
                <Grid container style={{ marginTop: 32 }}>
                  <Grid item xs={12} style={{ height: 200 }}>
                    <ReactQuill
                      onFocus={() => setIsSubjectTxtFocused(false)}
                      ref={quillRef}
                      style={{ height: 150 }}
                      theme="snow"
                      value={formik.values.html}
                      onChange={handleQuillChange}
                      modules={modules}
                    />
                  </Grid>
                </Grid>
                {/* Buttons */}
                <Grid
                  container
                  style={{ marginTop: 16 }}
                  className={classes.buttonContainer}
                >
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      variant="contained"
                      color="inherit"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      {id ? "Update" : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          lg={3}
          sx={{ display: { xs: "none", sm: "none", md: "block" } }}
        >
          <ParametersList
            parameters={Parameters_List}
            onItemClick={handleItemClick}
            formik={formik}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const ParametersList = ({
  parameters,
  onItemClick,
  formik,
  horizontal = false,
}) => {
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState(null);
  const [filteredParameters, setFilteredParameters] = useState([]);

  useEffect(() => {
    let sortedParameters = parameters.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    if (query != null) {
      const filtered = sortedParameters.filter((parameter) =>
        parameter.label.toLowerCase().includes(query?.toLowerCase())
      );
      setFilteredParameters(filtered);
    } else {
      setFilteredParameters(sortedParameters);
    }
  }, [parameters, query, formik?.values?.eventType]);

  const handleItemClick = (label) => {
    if (onItemClick) {
      onItemClick(label);
    }
  };

  const handleSearch = () => {
    if (query != null) {
      const filtered = parameters.filter((parameter) =>
        parameter.label.toLowerCase().includes(query?.toLowerCase())
      );
      setFilteredParameters(filtered);
    } else {
      setFilteredParameters(parameters);
    }
  };

  return (
    <Card sx={{ maxHeight: 530, overflow: "auto" }}>
      <Typography variant="h6" align="center" sx={{ my: 2 }}>
        Parameters List
      </Typography>
      {/* Search Box */}
      <Grid sx={{ mx: 2 }}>
        <ParametersSearchBox
          label="Search parameter"
          query={query}
          setQuery={setQuery}
          onSearch={handleSearch}
          setSearch={setSearch}
        />
      </Grid>

      <List
        style={
          horizontal
            ? { display: "flex", width: "100%", overflow: "scroll" }
            : {}
        }
      >
        {filteredParameters.map((dt) => (
          <ListItem
            button
            key={dt.label}
            sx={{ "&:hover": { cursor: "pointer" }, whiteSpace: "nowrap" }}
            onClick={() => handleItemClick(dt.label)}
          >
            <ListItemText>{dt.label}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default EmailTemplateForm;
