import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  cardGrid: {
    margin: "19px",
    alignItems: "center",
    textAlign: "center",
  },
  cardTypography: {
    color: "grey",
    marginTop: 2,
  },
}));

export default dynamicStyle;
