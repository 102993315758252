import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((them) => ({
  main: {
    "& .MuiTypography-root": {
      color: "grey",
      marginBottom: "12px",
      fontSize: "15px",
    },
  },
  box: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  cancelButton: {
    marginRight: "10px",
  },
  headingGrid: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
  },
  typographyGrid: {
    display: "flex",
    marginTop: "20px",
  },
  //   from: {
  //     "& .MuiTypography-root": {
  //       color: "grey",
  //       fontWeight: "bold",
  //       fontSize: 20,
  //       width: "50%",
  //       marginLeft: "15px",
  //     },
  //   },
}));

export default dynamicStyle;
