import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupIcon from "@mui/icons-material/Group";
import LabelIcon from "@mui/icons-material/Label";
import NotesIcon from "@mui/icons-material/Notes";
import EmailIcon from "@mui/icons-material/Email";
import {
  TransferWithinAStationOutlined,
  ManageAccounts,
} from "@mui/icons-material";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { ListItemIcon } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import LocalStorage from "../../managers/LocalStorage";

import { useAuth } from "../../hooks/useAuth";

// Create styled components for the sidebar and top bar
const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBarDrawer = ({ open, setOpen, isSmallScreen }) => {
  const { isLoggedIn, user } = useAuth();
  const location = useLocation();

  // Return null if user is not logged in
  if (!isLoggedIn) {
    return null;
  }

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleListItemClick = () => {
    if (isSmallScreen) {
      setOpen(false);
    }
  };

  // Method for access routes based on the role ...
  const hasEmailTemplateAccess = () => {
    const user = LocalStorage.getUser();
    if (
      [
        "hahmed@camclarkgroup.com",
        "ali@camclarkgroup.com",
        "nwirth@camclarkgroup.com",
        "pvaughn@camclarkgroup.com",
        "lvass@camclarkgroup.com",
        "bguday@camclarkgroup.com",
        "lbriglio@camclarkgroup.com",
      ].includes(user?.email)
    )
      return true;
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            marginTop: "64px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div onClick={handleListItemClick}>
          <List>
            <ListItem button component={Link} to="/" selected={isActive("/")}>
              <ListItemIcon style={{ display: "flex-end" }}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/employees"
              selected={isActive("/employees")}
            >
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="Employees" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/draft"
              selected={isActive("/draft")}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/transfer-employee-list"
              selected={isActive("/transfer-employee-list")}
            >
              <ListItemIcon>
                <TransferWithinAStationOutlined />
              </ListItemIcon>
              <ListItemText primary="Employee Transfer" />
            </ListItem>
            {/* Admin Routes access */}

            {/* Removal employee route */}
            <ListItem
              button
              component={Link}
              to="/removals"
              selected={isActive("/removals")}
            >
              <ListItemIcon>
                <GroupRemoveIcon />
              </ListItemIcon>
              <ListItemText primary="Employee Removal" />
            </ListItem>
            {/* Employee leaves route */}
            <ListItem
              button
              component={Link}
              to="/leaves"
              selected={isActive("/leaves")}
            >
              <ListItemIcon>
                <PersonOffIcon />
              </ListItemIcon>
              <ListItemText primary="Employee Leaves" />
            </ListItem>
            {/* Reports */}
            {/* <ListItem
              button
              component={Link}
              to="/reports"
              selected={isActive("/reports")}
            >
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem> */}
            {user.role === "Admin" && (
              <>
                {/* Logs */}
                <ListItem
                  button
                  component={Link}
                  to="/logs"
                  selected={isActive("/logs")}
                >
                  <ListItemIcon>
                    <NotesIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logs" />
                </ListItem>
                {/* Email Template */}
                {hasEmailTemplateAccess() ? (
                  <ListItem
                    button
                    component={Link}
                    to="/email-templates"
                    selected={isActive("/email-templates")}
                  >
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Email Templates" />
                  </ListItem>
                ) : (
                  <></>
                )}
                {/* Users List */}
                <ListItem
                  button
                  component={Link}
                  to="/users"
                  selected={isActive("/users")}
                >
                  <ListItemIcon>
                    <ManageAccounts />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>

                {/* Tags */}
                <ListItem
                  button
                  component={Link}
                  to="/tags-management"
                  selected={isActive("/tags-management")}
                >
                  <ListItemIcon>
                    <LabelIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tags Management" />
                </ListItem>
              </>
            )}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default SideBarDrawer;
