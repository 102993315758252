import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useQuery } from "react-query";

import { menuOptions } from "../../constants/AppConstants";
import DashBoardApi from "../../services/APIs/DashBoard.Api";
import { ReportTable, AllEmployeeTab, DirectEmployeeTab } from "./components";

const Reports = () => {
  const [tabValue, setTabValue] = React.useState(0);

  // Handle tab change ..
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // all employees filters states ..
  const [allEmpreportType, setAllEmpReportType] = useState("");
  const [allEmpStartDate, setAllEmpStartDate] = useState(null);
  const [allEmpendDate, setAllEmpEndDate] = useState(null);
  // Direct employees filters states ..
  const [directEmpreportType, setDirectEmpReportType] = useState("");
  const [directEmpStartDate, setDirectEmpStartDate] = useState(null);
  const [directEmpendDate, setDirectEmpEndDate] = useState(null);

  // state to setTable values  ....
  const [allEmpTableData, setAllEmpTableData] = useState([]);
  const [directEmpTableData, setDirectEmpTableData] = useState([]);
  // states for hire and fire employees ..
  const [hireFireEmp, setHireFireEmp] = useState(null);
  const [directHireFireEmp, setDirectHireFireEmp] = useState(null);

  // Get All Reports
  const handleFilterReport = async () => {
    if (allEmpreportType && allEmpStartDate && allEmpendDate) {
      if (allEmpreportType === "Hire & Fire") {
        console.log("Hire and fire all emp called");
        const response = await DashBoardApi.getHireAndFireReport({
          startDate: allEmpStartDate,
          endDate: allEmpendDate,
        });
        setHireFireEmp(response);
        setAllEmpTableData(null);
      } else {
        const response = await DashBoardApi.getReports({
          reportType: allEmpreportType,
          startDate: allEmpStartDate,
          endDate: allEmpendDate,
        });
        setAllEmpTableData(response);
        setHireFireEmp(null);
      }
    }
  };

  // Get direct employee reports ..
  const directEmployeeReports = async () => {
    if (directEmpreportType && directEmpStartDate && directEmpendDate) {
      // alert("get reports ");
      if (directEmpreportType === "Hire & Fire") {
        const response = await DashBoardApi.getDirectEmployeeHireFireReports({
          startDate: directEmpStartDate,
          endDate: directEmpendDate,
        });
        setDirectHireFireEmp(response);
        setDirectEmpTableData(null);
      } else {
        // alert("get reports ");

        const response = await DashBoardApi.getDirectEmployeeReports({
          reportType: directEmpreportType,
          startDate: directEmpStartDate,
          endDate: directEmpendDate,
        });
        console.log("response", response);
        setDirectEmpTableData(response);
        setDirectHireFireEmp(null);
      }
    }
  };

  const { data } = useQuery(
    ["GET_REPORTS", allEmpreportType, allEmpStartDate, allEmpendDate],
    handleFilterReport
  );

  const { data: directEmployeeData } = useQuery(
    [
      "DIRECT_EMPLOYEE_REPORTS",
      directEmpreportType,
      directEmpStartDate,
      directEmpendDate,
    ],
    directEmployeeReports
  );

  const handleReset = () => {
    setAllEmpReportType("");
    setAllEmpStartDate(null);
    setAllEmpEndDate(null);
    setAllEmpTableData([]);
  };
  const handleResetFilter = () => {
    setDirectEmpReportType("");
    setDirectEmpStartDate(null);
    setDirectEmpEndDate(null);
    setDirectEmpTableData([]);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid md={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              allowScrollButtonsMobile
              variant="scrollable"
            >
              <Tab label="Direct Employees" {...a11yProps(0)} />
              <Tab label="All Employees" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* Direct employees tab */}
          <CustomTabPanel value={tabValue} index={0}>
            <DirectEmployeeTab
              reportType={directEmpreportType}
              setReportType={setDirectEmpReportType}
              startDate={directEmpStartDate}
              setStartDate={setDirectEmpStartDate}
              endDate={directEmpendDate}
              setEndDate={setDirectEmpEndDate}
              handleReset={handleResetFilter}
              data={directEmpTableData}
              hireFireEmployees={directHireFireEmp}
            />
          </CustomTabPanel>
          {/* All employees tab */}
          <CustomTabPanel value={tabValue} index={1}>
            <AllEmployeeTab
              reportType={allEmpreportType}
              setReportType={setAllEmpReportType}
              startDate={allEmpStartDate}
              setStartDate={setAllEmpStartDate}
              endDate={allEmpendDate}
              setEndDate={setAllEmpEndDate}
              handleReset={handleReset}
              data={allEmpTableData}
              hireFireEmployees={hireFireEmp}
            />
          </CustomTabPanel>
        </Grid>
      </Grid>
    </>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default Reports;
