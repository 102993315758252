import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import dynamicStyle from "./styles";

const ListDialog = ({ open, setOpen, data, dialogTitle }) => {
  const classes = dynamicStyle();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "430px",
              maxHeight: "400px",
            },
          },
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {dialogTitle}
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* attachment list */}
          {data ? (
            <>
              <List>
                {data &&
                  data?.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={item?.name} />
                    </ListItem>
                  ))}
              </List>
            </>
          ) : (
            <Typography>No attachment exists</Typography>
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ListDialog;
