import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  link: {
    textDecoration: "none",
  },
}));

export default dynamicStyle;
