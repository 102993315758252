import { api } from "../../utils";
import { CREATE_TAG, DELETE_TAG, GET_TAGS, SINGLE_TAG } from "../ApiConstants";

class TagsApi {
  static sharedInstance = new TagsApi();

  constructor() {
    if (TagsApi.sharedInstance != null) {
      return TagsApi.sharedInstance;
    }
  }

  //   Get comment tags ..
  async getCommentTags() {
    try {
      const response = await api.get(GET_TAGS);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
  // Create Tag ..
  async createTag(body) {
    try {
      const response = await api.post(CREATE_TAG, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Delete Tag
  async deleteTag(id) {
    try {
      const response = await api.delete(`${SINGLE_TAG}/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Get Single Tag ...
  async getSingleTag(id) {
    try {
      const response = await api.get(`${SINGLE_TAG}/${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Update Tag ...
  async updateTag(body, id) {
    try {
      const response = await api.put(`${SINGLE_TAG}/${id}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
}

export default TagsApi.sharedInstance;
