import React from "react";
import {
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";

const EmployeeRadioSection = ({ formik, handleRadioChange }) => {
  return (
    <>
      {/* Restrict all employee access */}
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="restrictEmployeeAccess"
            name="restrictEmployeeAccess"
            value={formik.values.restrictEmployeeAccess ?? ""}
            onChange={(e) => handleRadioChange("restrict")}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Restrict all employee access"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* Employee existing access */}
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="employeeExistingAccess"
            name="employeeExistingAccess"
            value={formik.values.employeeExistingAccess ?? ""}
            onChange={(e) => handleRadioChange("existing")}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Let employee keep their existing access"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* Selective acces radio */}
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="selectiveAccess"
            name="selectiveAccess"
            value={formik.values.selectiveAccess ?? ""}
            onChange={(e) => handleRadioChange("selectiveaccess")}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Selective access"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default EmployeeRadioSection;
