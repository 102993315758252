import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import EmployeeApi from "../../services/APIs/Employee.Api";
import { useQuery } from "react-query";
import MUIDataTable from "mui-datatables";
import { FiberManualRecord } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import moment from "moment";
import React from "react";

import dynamicStyle from "./styles";

const ViewTransferEmployee = () => {
  const navigate = useNavigate();

  const classes = dynamicStyle();

  //   Get tranfers employee list api call ..
  const { data } = useQuery("GET_TRANSFER_EMPLOYEE", () =>
    EmployeeApi.getTransferEmployees()
  );

  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "fullName",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },

    {
      name: "email",
      label: "Email",
    },
    {
      name: "oldDealership",
      label: "From Dealership",
    },
    {
      name: "newDealership",
      label: "To Dealership",
    },
    {
      name: "newPosition",
      label: "New Position",
    },

    {
      name: "transferDate",
      label: "Transfer Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    // Completed Column
    {
      name: "completed",
      label: "Completed",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const isCompleted = value === 1;
          const color = isCompleted ? green[500] : red[500];

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <FiberManualRecord
                sx={{
                  color: color,
                  fontSize: "10px",
                  marginRight: "5px",
                }}
              />
              {isCompleted ? "YES" : "NO"}
            </div>
          );
        },
      },
    },
    // Actions  ...
    // {
    //   name: "actions",
    //   label: "Actions",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const id = tableMeta.rowData[0];
    //       return (
    //         <>
    //           <IconButton
    //             onClick={() => {
    //               handleConfirmOpen(id);
    //             }}
    //           >
    //             <DeleteIcon />
    //           </IconButton>
    //           <IconButton
    //             onClick={() => {
    //               handleUpdatNavigate(id);
    //             }}
    //           >
    //             <EditIcon />
    //           </IconButton>
    //         </>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    // responsive,
    selectableRows: "none",

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const handleNavigate = () => {
    navigate("/transfer-employee");
  };
  return (
    <Grid>
      {/* <Grid className={classes.buttonGrid}>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          onClick={handleNavigate}
        >
          Create Transfer Request
        </Button>
      </Grid> */}
      {/* MUI Transfer Employee Table  */}

      <Grid className={classes.tableGrid}>
        <MUIDataTable
          title={"Transfer Employee list"}
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default ViewTransferEmployee;
