// Employee apis constants  ..
export const GET_EMPLOYEES = "employee/allEmployees";
export const GET_EMPLOYEES_DS_DPT = "employee/employees";
export const CREATE_EMPLOYEE = "employee/create";
export const DELETE_EMPLOYEE = "employee";
export const GET_SINGLE_EMPLOYEE = "employee";
export const UPDATE_EMPLOYEE = "employee/update";
export const SEARCH_EMPLOYEE = "employee/searchemployee";
export const GET_EMPLOYEES_FORDD = "employee/employeelist/dropdown";
export const MANAGERS_EMPLOYEES = "employee/employeeslist/managersemployees";
export const GET_EMPLOYEES_EMAILS = "employee/employess/emails";
export const CHECK_SIN = "employee/employess/checksin";
export const CHECK_SIN_ON_UPDATE = "employee/checksin";

// DealerShips adn Departments apis contants ...
export const GET_DEALERSSHIPS = "dealership/allDealersShips";
export const GET_USER_DEALERSHIPS = "dealership";
export const GET_DEPARTMENTS = "department/allDepartments";
export const GET_USER_DEPARTMENTS = "department";

// Authenticatios apis constants ...
export const SING_UP = "user/register";
export const SING_IN = "user/login";
export const ALL_USERS = "user/users";
export const CREATE_USER = "user/create";
export const DELETE_USER = "user/";
export const GET_SINGEL_USER = "user/";
export const UPDATE_USER = "user/";
export const GET_USERS_FOR_FILTERS = "user/usersforfilters";
export const GET_MANAGERS = "user/users/managers";

// Transfer Employee apis ..
export const CREATE_TRANSFER_EMPLOYEE = "transfer/create";
export const GET_TRANSFER_EMPLOYEES = "transfer/alltransferemployees";

// Draft apis ..
export const CREATE_DRAFT = "draft/create";
export const UPDATE_DRAFT = "draft/update";
export const DELETE_DRAFT = "/draft/delete";

// Tags apis ..
export const GET_TAGS = "tag/tags";
export const CREATE_TAG = "tag/create";
export const SINGLE_TAG = "tag";

// File apis ..
export const CREATE_FILE = "file/create";
export const GET_EMPLOYEE_FILE = "file/employee";
export const SEARCH_EMPLOYEE_FILE = "file/search";
export const DOWNLOAD_FILE = "file/download";

// Event Types api  ...
export const GET_EVENT_TYPES = "event/allevents";
// Email Templates apis ..
export const CREATE_EMAIL_TEMPLATE = "emailtemplate/createtemplate";
export const GET_TEMPLATES_LIST = "emailtemplate/templateslist";
export const DELETE_EMAIL_TEMPLATE = "emailtemplate/";
export const GET_EMAIL_TEMPLATE = "emailtemplate/template/";
export const UPDATE_EMAIL_TEMPLATE = "/emailtemplate/template/update";

// Get user logs ...
export const GET_USER_LOGS = "userlogs/logs";
// Get dashboard analytics ..
export const GET_EMPLOYEE_ANALYTICS = "dashboard/employeeanalytics";
export const GET_TRANSFER_EMPLOYEE_ANALYTICS =
  "dashboard/transferemployeeanalytics";
export const GET_EMPLOYEE_ANNIVERSARIES = "dashboard/employeeanniversaries";
export const GET_REMOVAL_EMPLOYEES_ANALYTICS = "dashboard/removalemployees";
export const GET_EMPLOYEE_LEAVES_ANALYTICS = "dashboard/employeesleaves";
export const GET_EMPLOYEE_RETURN_TO_WORK = "dashboard/returntowork";
export const GET_REPORTS = "dashboard/reports";
export const GET_HIRE_FIRE_REPORTS = `${GET_REPORTS}/hireandfires`;
export const GET_DIRECT_HIRE_FIRE_REPORTS = `${GET_REPORTS}/directemployees/hireandfire`;
export const DIRECT_EMPLOYEE_REPORTS = `${GET_REPORTS}/directemployees`;

// Manager dashboard api ..
export const GET_MANAGER_EMPLOYEE_ANALYTICS =
  "dashboard/manager/employeeanalytics";
export const GET_MANAGER_TRANSFER_EMPLOYEE_ANALYTICS =
  "dashboard/manager/transferemployeeanalytics";
export const GET_MANAGER_EMPLOYEE_ANNIVERSARIES =
  "dashboard/manager/employeanniversaries";
export const GET_MANAGER_REMOVAL_EMPLOYEES_ANALYTICS =
  "dashboard/manager/upcomingremovals";
export const GET_EMANAGER_EMPLOYEE_LEAVES_ANALYTICS =
  "dashboard/manager/upcomingleaves";
export const GET_MANAGER_EMPLOYEE_RETURN_TO_WORK =
  "dashboard/manager/employeesreturntowork";
// Employee removal apis ...
export const GET_REMOVAL_EMPLOYEES = "removalemployee/";
export const CREATE_REMOVAL_EMPLOYEE = "removalemployee/create";
export const UPDATE_REMOVAL_EMPLOYEE = "removalemployee/update";
export const CANCEL_EMPLOYEE_REMOVAL = "removalemployee/cancel";

// Leave of absence apis constants ..
export const GET_LEAVE_TYPES = "leaveofabsence/leavetypes";
export const CREATE_EMPLOYEE_LEAVE = "leaveofabsence/create";
export const GET_EMPLOYEE_LEAVES = "leaveofabsence/leaves";
