export const TOKEN = "token";
export const USER = "user";

export const Parameters_List = [
  { label: "fullName" },
  { label: "knownAs" },
  { label: "position" },
  { label: "phone" },
  { label: "start date" },
  { label: "birthday" },
  { label: "email" },
  { label: "address" },
  { label: "city" },
  { label: "postalCode" },
  { label: "refered by" },
  { label: "reporting manager" },
  { label: "copy from employee" },
  { label: "additional notes" },
  { label: "share birthday" },
  { label: "gender" },
  { label: "department" },
  { label: "dealership" },
  { label: "employee type" },
  { label: "pronouns" },
  { label: "state" },
  { label: "employeeNumber" },
  { label: "phoneExtension" },
];

export const menuOptions = [
  { label: "Birthdays", value: "Birthdays" },
  { label: "Transfer Request", value: "Transfer Request" },
  { label: "Anniversaries", value: "Anniversaries" },
  { label: "Removals", value: "Removals" },
  { label: "Employees Leaves", value: "Employees Leaves" },
  { label: "Employee Return To Work", value: "Employee Return To Work" },
  { label: "Hire & Fire", value: "Hire & Fire" },
];

export const canadaProvince = [
  { id: 0, label: "Alberta", value: "Alberta" },
  { id: 1, label: "British Columbia", value: "British Columbia" },
  { id: 3, label: "Manitoba", value: "Manitoba" },
  { id: 4, label: "New Brunswick", value: "New Brunswick" },
  {
    id: 5,
    label: "Newfoundland and Labrador",
    value: "Newfoundland and Labrador",
  },
  { id: 6, label: "Nova Scotia", value: "Nova Scotia" },
  { id: 7, label: "Ontario", value: "Ontario" },
  { id: 8, label: "Prince Edward Island", value: "Prince Edward Island" },
  { id: 9, label: "Quebec", value: "Quebec" },
  { id: 10, label: "Saskatchewan", value: "Saskatchewan" },
];

export const americaStates = [
  { id: 1, label: "Alabama", value: "Alabama" },
  { id: 2, label: "Alaska", value: "Alaska" },
  { id: 3, label: "Arizona", value: "Arizona" },
  { id: 4, label: "Arkansas", value: "Arkansas" },
  { id: 5, label: "California", value: "California" },
  { id: 6, label: "Colorado", value: "Colorado" },
  { id: 7, label: "Connecticut", value: "Connecticut" },
  { id: 8, label: "Delaware", value: "Delaware" },
  { id: 9, label: "Florida", value: "Florida" },
  { id: 10, label: "Georgia", value: "Georgia" },
  { id: 11, label: "Hawaii", value: "Hawaii" },
  { id: 12, label: "Idaho", value: "Idaho" },
  { id: 13, label: "Illinois", value: "Illinois" },
  { id: 14, label: "Indiana", value: "Indiana" },
  { id: 15, label: "Iowa", value: "Iowa" },
  { id: 16, label: "Kansas", value: "Kansas" },
  { id: 17, label: "Kentucky", value: "Kentucky" },
  { id: 18, label: "Louisiana", value: "Louisiana" },
  { id: 19, label: "Maine", value: "Maine" },
  { id: 20, label: "Maryland", value: "Maryland" },
  { id: 21, label: "Massachusetts", value: "Massachusetts" },
  { id: 22, label: "Michigan", value: "Michigan" },
  { id: 23, label: "Minnesota", value: "Minnesota" },
  { id: 24, label: "Mississippi", value: "Mississippi" },
  { id: 25, label: "Missouri", value: "Missouri" },
  { id: 26, label: "Montana", value: "Montana" },
  { id: 27, label: "Nebraska", value: "Nebraska" },
  { id: 28, label: "Nevada", value: "Nevada" },
  { id: 29, label: "New Hampshire", value: "New Hampshire" },
  { id: 30, label: "New Jersey", value: "New Jersey" },
  { id: 31, label: "New Mexico", value: "New Mexico" },
  { id: 32, label: "New York", value: "New York" },
  { id: 33, label: "North Carolina", value: "North Carolina" },
  { id: 34, label: "North Dakota", value: "North Dakota" },
  { id: 35, label: "Ohio", value: "Ohio" },
  { id: 36, label: "Oklahoma", value: "Oklahoma" },
  { id: 37, label: "Oregon", value: "Oregon" },
  { id: 38, label: "Pennsylvania", value: "Pennsylvania" },
  { id: 39, label: "Rhode Island", value: "Rhode Island" },
  { id: 40, label: "South Carolina", value: "South Carolina" },
  { id: 41, label: "South Dakota", value: "South Dakota" },
  { id: 42, label: "Tennessee", value: "Tennessee" },
  { id: 43, label: "Texas", value: "Texas" },
  { id: 44, label: "Utah", value: "Utah" },
  { id: 45, label: "Vermont", value: "Vermont" },
  { id: 46, label: "Virginia", value: "Virginia" },
  { id: 47, label: "Washington", value: "Washington" },
  { id: 48, label: "West Virginia", value: "West Virginia" },
  { id: 49, label: "Wisconsin", value: "Wisconsin" },
  { id: 50, label: "Wyoming", value: "Wyoming" },
];
