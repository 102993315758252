import React, { useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import moment from "moment";
import { Download } from "@mui/icons-material";
import { CSVLink, CSVDownload } from "react-csv";
import CircularProgress from "@mui/material/CircularProgress";

const ReportTable = ({ data, title, hireFireEmployees }) => {
  // For Downloading ...
  const [isDownloading, setIsDownloading] = useState(false);
  const [hireCsvDownLoading, setHireCsvDownLoading] = useState(false);
  const [fireCsvDownLoading, setFireCsvDownloading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const csvDownloadRef = useRef(null);
  const hireCsvDownloadRef = useRef(null);
  const fireCsvDownloadRef = useRef(null);

  console.log("Hireeee ==", hireFireEmployees?.hire);
  console.log("Fireeee ====", hireFireEmployees?.fire);
  console.log("DAta ===", data);
  const hasName = data?.some((item) => item.hasOwnProperty("Name"));
  const hasFullName = data?.some((item) => item.hasOwnProperty("fullName"));
  const hasBirthDay = data?.some((item) => item.hasOwnProperty("Birthday"));
  const hasDate = data?.some((item) => item.hasOwnProperty("Date"));
  const hasAnniversary = data?.some((item) =>
    item.hasOwnProperty("anniversaryDate")
  );
  const hasYearOfWork = data?.some((item) =>
    item.hasOwnProperty("workOfYears")
  );

  const hasRemovalDate = data?.some((item) =>
    item.hasOwnProperty("removalDate")
  );

  const hasUpComingDays = data?.some((item) =>
    item.hasOwnProperty("upComingDays")
  );

  const hasStartDate = data?.some((item) => item.hasOwnProperty("startDate"));
  const hasDepartureDate = data?.some((item) =>
    item.hasOwnProperty("dateOfDeparture")
  );

  // CSV Headers  ...
  const csvHeaders = [
    ...(hasName ? [{ label: "Name", key: "Name" }] : []),
    ...(hasFullName ? [{ label: "Name", key: "fullName" }] : []),
    ...(hasBirthDay ? [{ label: "Birthday", key: "Birthday" }] : []),
    ...(hasDate ? [{ label: "Date", key: "Date" }] : []),
    ...(hasAnniversary
      ? [{ label: "Anniversary Date", key: "anniversaryDate" }]
      : []),
    ...(hasYearOfWork ? [{ label: "Work Of Years", key: "workOfYears" }] : []),

    ...(hasRemovalDate ? [{ label: "Removal Date", key: "removalDate" }] : []),
    ...(hasUpComingDays
      ? [{ label: "Upcoming Days", key: "upComingDays" }]
      : []),
    ...(hasStartDate ? [{ label: "Start Date", key: "startDate" }] : []),
    ...(hasDepartureDate
      ? [{ label: "Date Of Departure", key: "dateOfDeparture" }]
      : []),
  ];

  const hasHireFullName = hireFireEmployees?.hire?.some((item) =>
    item.hasOwnProperty("fullName")
  );
  const hasHireStartDate = hireFireEmployees?.hire?.some((item) =>
    item.hasOwnProperty("startDate")
  );

  const hasFireDD = hireFireEmployees?.fire?.some((item) =>
    item.hasOwnProperty("dateOfDeparture")
  );
  const hasFireFullName = hireFireEmployees?.fire?.some((item) =>
    item.hasOwnProperty("fullName")
  );

  // Hire and fire csvHeaders ..
  const csvHireHeader = [
    ...(hasHireFullName ? [{ label: "Name", key: "fullName" }] : []),
    ...(hasHireStartDate ? [{ label: "Start Date", key: "startDate" }] : []),
  ];
  const csvFireHeader = [
    ...(hasFireFullName ? [{ label: "Name", key: "fullName" }] : []),
    ...(hasFireDD ? [{ label: "Date", key: "dateOfDeparture" }] : []),
  ];
  // Format table header
  const formatHeader = (header) => {
    return header
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^\w/, (c) => c.toUpperCase());
  };
  // If value is date ..
  const formatDateValue = (value) => {
    if (moment(value, moment.ISO_8601, true).isValid()) {
      return moment(value).format("YYYY-MM-DD");
    }
    return value;
  };

  const handleClick = (csvType) => {
    // Download CSV Method
    switch (csvType) {
      case "Hire":
        setHireCsvDownLoading(true);
        setTimeout(() => {
          setHireCsvDownLoading(false);
          downloadCSV(csvType);
        }, 3000);
        break;
      case "Fire":
        setFireCsvDownloading(true);
        setTimeout(() => {
          setFireCsvDownloading(false);
          downloadCSV(csvType);
        }, 3000);
        break;
      case "General":
        setIsDownloading(true);
        setTimeout(() => {
          setIsDownloading(false);
          downloadCSV(csvType);
        }, 3000);
        break;
      default:
        break;
    }
  };

  const downloadCSV = (csvType) => {
    switch (csvType) {
      case "Hire":
        setTimeout(() => {
          hireCsvDownloadRef.current.link.click();
        }, 1000);
        setCsvData(hireFireEmployees.hire);
        break;
      case "Fire":
        setTimeout(() => {
          fireCsvDownloadRef.current.link.click();
        }, 1000);
        setCsvData(hireFireEmployees.fire);
        break;
      case "General":
        setTimeout(() => {
          csvDownloadRef.current.link.click();
        }, 1000);
        setCsvData(data);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <Grid sx={{ mb: 3 }}>
            <CSVLink
              data={data}
              headers={csvHeaders}
              filename="employee_report.csv"
              ref={csvDownloadRef}
            />

            <Button
              startIcon={<Download />}
              style={{ marginBottom: "5px" }}
              variant="contained"
              color="primary"
              disabled={isDownloading}
              onClick={() => handleClick("General")}
              endIcon={
                isDownloading && <CircularProgress size={18} color="inherit" />
              }
            >
              Download csv
            </Button>
          </Grid>
          <TableContainer component={Paper} sx={{ width: 555 }}>
            {title && (
              <Typography variant="h6" component="div" sx={{ padding: 2 }}>
                {title}
              </Typography>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  {/* Table Header */}
                  {Object.keys(data[0]).map((key) => (
                    <TableCell key={key}>{formatHeader(key)}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Table Values */}
                {data?.map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((value, index) => (
                      <TableCell key={index}>
                        {formatDateValue(value)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          {hireFireEmployees && hireFireEmployees !== null && (
            <>
              <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                {/* Hire Employees */}
                <Grid item md={6} xs={12}>
                  <Grid sx={{ mb: 3 }}>
                    <CSVLink
                      data={hireFireEmployees.hire}
                      headers={csvHireHeader}
                      filename="employee_hire_report.csv"
                      ref={hireCsvDownloadRef}
                    />

                    <Button
                      startIcon={<Download />}
                      style={{ marginBottom: "5px" }}
                      variant="contained"
                      color="primary"
                      disabled={hireCsvDownLoading}
                      onClick={() => handleClick("Hire")}
                      endIcon={
                        hireCsvDownLoading && (
                          <CircularProgress size={18} color="inherit" />
                        )
                      }
                    >
                      Download csv
                    </Button>
                  </Grid>
                  <TableContainer component={Paper} sx={{ width: 555 }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ padding: 2 }}
                    >
                      Hire Employee
                    </Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {/* Table Header */}
                          <TableCell>Name</TableCell>
                          <TableCell>Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Table Values */}
                        {hireFireEmployees.hire.map((row, index) => (
                          <TableRow key={index}>
                            {Object.values(row).map((value, index) => (
                              <TableCell key={index}>
                                {formatDateValue(value)}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {/* Fire Employees */}
                <Grid item md={6} xs={12}>
                  <Grid sx={{ mb: 3 }}>
                    <CSVLink
                      data={hireFireEmployees.fire}
                      headers={csvFireHeader}
                      filename="employee_fire_report.csv"
                      ref={fireCsvDownloadRef}
                    />

                    <Button
                      startIcon={<Download />}
                      style={{ marginBottom: "5px" }}
                      variant="contained"
                      color="primary"
                      disabled={fireCsvDownLoading}
                      onClick={() => handleClick("Fire")}
                      endIcon={
                        fireCsvDownLoading && (
                          <CircularProgress size={18} color="inherit" />
                        )
                      }
                    >
                      Download csv
                    </Button>
                  </Grid>
                  <TableContainer component={Paper} sx={{ width: 555 }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ padding: 2 }}
                    >
                      Fire Employee
                    </Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {/* Table Header */}
                          <TableCell>Name</TableCell>
                          <TableCell>Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Table Values */}
                        {hireFireEmployees.fire.map((row, index) => (
                          <TableRow key={index}>
                            {Object.values(row).map((value, index) => (
                              <TableCell key={index}>
                                {formatDateValue(value)}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ReportTable;
