import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  TextField,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import { userValidationSchema } from "../../utils/validations";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { isNull } from "lodash";
import AuthApi from "../../services/APIs/Auth.Api";

import dynamicStyle from "./styles";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import DealerShipApi from "../../services/APIs/DealerShip.Api";

const roleOptions = ["Admin", "General Manager", "Manager"];

const initialValues = {
  userName: "",
  email: "",
  role: "",
  dealership: [],
  department: [],
};

const AddUser = () => {
  const classes = dynamicStyle();

  const navigate = useNavigate();

  const { id } = useParams();
  const match = useMatch(`user/update/${id}`);

  const [loading, setLoading] = useState(false);
  const [singleUser, setSingleUser] = useState([]);

  // Get all dealers ships ...
  const { data: dealerships } = useQuery("GET_DEALERS_SHIPS", () =>
    DealerShipApi.getDealersShips()
  );

  // Get all departments  ..
  const { data: departments } = useQuery("GET_DEPARTMENTS", () =>
    DealerShipApi.getDepartements()
  );

  // Get Single Employee By ID...
  useEffect(() => {
    if (id !== undefined) {
      const getUserByID = async (id) => {
        const response = await AuthApi.getSingleUser(id);
        setSingleUser(response);
      };
      getUserByID(id);
    }
  }, [id]);

  // Set Formik Fields on update user form  ..
  useEffect(() => {
    if (!isNull(match) && singleUser.length > 0) {
      const { userName, email, role, dealerships, departments } =
        singleUser[0] ?? {};

      console.log("dealerships =", dealerships);
      formik.setFieldValue("userName", userName);
      formik.setFieldValue("email", email);
      formik.setFieldValue("role", role);
      formik.setFieldValue("dealership", dealerships);
      formik.setFieldValue("department", departments);
    }
  }, [singleUser]);

  // Signup user  ...
  const { mutate: addUser, isLoading } = useMutation(
    (body) => (id ? AuthApi.updateUser(id, body) : AuthApi.createUser(body)),
    {
      onSuccess: (res) => {
        if (res.success) {
          toast.success(res.message);
          navigate("/users");
          formik.resetForm();
        } else {
          toast.error(res.message);
        }
      },
      onError: (error) => toast.error(error.message),
    }
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: userValidationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  });

  //
  const handleSubmit = (values) => {
    addUser(values);
  };

  // Handle Change Dealer Ships
  const handleDealerShip = (event, values) => {
    formik.setFieldValue("dealership", values);
  };
  // Handle Change Departments
  const handleDepartment = (event, values) => {
    formik.setFieldValue("department", values);
  };

  return (
    <Grid className={classes.root}>
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Card style={{ marginTop: "20px" }} sx={{ width: "50%", margin: "auto" }}>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <CardContent>
            <Grid className={classes.titelGrid}>
              <Typography className={classes.title}>
                {id ? "Update user" : "Create new user"}
              </Typography>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  name="userName"
                  label="User name"
                  type="text"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userName && Boolean(formik.errors.userName)
                  }
                  helperText={formik.touched.userName && formik.errors.userName}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                  size="small"
                />
              </Grid>
              {/* Role Auto Complete  */}
              <Grid item md={12} xs={12}>
                <Autocomplete
                  id="role-autocomplete"
                  options={roleOptions}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("role", newValue || "");
                  }}
                  value={
                    roleOptions?.find(
                      (option) => option === formik.values.role
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="role"
                      size="small"
                      label="Role"
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      helperText={formik.touched.role && formik.errors.role}
                    />
                  )}
                />
              </Grid>

              {/* Dealership autocomplete */}
              <Grid item md={12} xs={12}>
                <Autocomplete
                  multiple
                  disabled={formik.values.role === "Admin"}
                  options={dealerships || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.ID === value.ID}
                  value={formik.values.dealership || []}
                  onChange={handleDealerShip}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dealership"
                      variant="outlined"
                      fullWidth
                      size="small"
                      name="dealership"
                    />
                  )}
                />
              </Grid>
              {/* Department autocomplete */}
              <Grid item md={12} xs={12}>
                <Autocomplete
                  multiple
                  disabled={
                    formik.values.role === "Admin" ||
                    formik.values.role === "General Manager"
                  }
                  options={departments || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.ID === value.ID}
                  value={formik.values.department || []}
                  onChange={handleDepartment}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department"
                      variant="outlined"
                      fullWidth
                      name="department"
                      size="small"
                    />
                  )}
                />
              </Grid>
              {/* Buttons Grid  */}
              <Grid item md={12} xs={12}>
                <Button
                  type="submit"
                  disabled={loading}
                  endIcon={
                    loading && <CircularProgress size={26} color="inherit" />
                  }
                  variant="contained"
                  fullWidth
                >
                  {id ? "Update User" : "Create User"}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Card>
    </Grid>
  );
};

export default AddUser;
