import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((them) => ({
  main: {
    "& .MuiTypography-root": {
      color: "grey",
      marginBottom: "12px",
      fontSize: "15px",
    },
  },
  box: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  cancelButton: {
    marginRight: "10px",
  },
  headingGrid: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
  },
  typographyGrid: {
    display: "flex",
    marginTop: "20px",
  },
  dialogTitle: {
    backgroundColor: "#1976d2",
    color: "white",
    textTransform: "uppercase",
    justifyContent: "space-between",
    display: "flex",
    height: 70,
  },
  dialogContent: {
    marginTop: "10px",
  },
  deleteButton: {
    backgroundColor: "#ca0014",
    color: "white",
  },
  iconsStyle: {
    marginRight: "10px",
  },
}));

export default dynamicStyle;
