import {
  Autocomplete,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { isNull } from "lodash";
import { useFormik } from "formik";

import DealerShipApi from "../../services/APIs/DealerShip.Api";
import LocalStorage from "../../managers/LocalStorage";

import dynamicStyle from "./styles";
import EmployeeApi from "../../services/APIs/Employee.Api";
import { transferEmployeeValidationSchema } from "../../utils/validations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const initialValues = {
  oldDealership: "",
  newDealership: "",
  oldDepartment: "",
  newDepartment: "",
  fullName: "",
  email: "",
  transferDate: null,
  currentPostion: "",
  newPosition: "",
  additionalNotes: "",
};

const EmployeeTransferForm = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const user = LocalStorage.getUser();

  const classes = dynamicStyle();

  const [singleEmployee, setSingleEmployee] = useState([]);
  const [dealerships, setDealerShips] = useState([]);
  const [departments, setDepartMents] = useState([]);

  // Get Dealerships Methods  ...
  const getDealerships = async () => {
    if (user?.role === "Admin") {
      const response = await DealerShipApi.getDealersShips();
      setDealerShips(response);
    } else {
      const response = await DealerShipApi.getUserDealerships(user?.id);
      setDealerShips(response[0]?.dealerships);
    }
  };

  // Get Departments Mehtod   ..
  const getDepartements = async () => {
    if (user?.role === "Manager") {
      const response = await DealerShipApi.getUserDepartmetns(user?.id);
      setDepartMents(response[0]?.departments);
    } else {
      const response = await DealerShipApi.getDepartements();
      setDepartMents(response);
    }
  };

  useEffect(() => {
    getDealerships();
    getDepartements();
  }, []);

  const { mutate: addTransferEmployee } = useMutation(
    (body) => EmployeeApi.createTransferEmployee(body),
    {
      onSuccess: (res) => {
        if (res.success) {
          toast.success(res.message);
          navigate("/transfer-employee-list");
          formik.resetForm();
        } else {
          toast.error(res.message);
        }
      },
      onError: (error) => toast.error(error.message),
    }
  );

  const handleSubmit = (values) => {
    addTransferEmployee(values);
  };

  // Formik ..
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: transferEmployeeValidationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  // Get Employee By ID  ...
  useEffect(() => {
    const getEmployeeByID = async (id) => {
      const response = await EmployeeApi.getEmployeeByID(id);
      setSingleEmployee(response);
    };
    getEmployeeByID(id);
  }, [id]);

  // Setting Formik Values (old values )
  useEffect(() => {
    if (singleEmployee?.length > 0) {
      formik.setFieldValue("fullName", singleEmployee[0]?.fullName);
      formik.setFieldValue("oldDealership", singleEmployee[0]?.dealership);
      formik.setFieldValue("oldDepartment", singleEmployee[0]?.department);
      formik.setFieldValue("email", singleEmployee[0]?.email);
      formik.setFieldValue("currentPostion", singleEmployee[0]?.position);
    }
  }, [singleEmployee]);

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    // Main Container
    <Box className={classes.box}>
      <Card sx={{ maxWidth: 950, width: "70%", my: 2 }}>
        <Grid className={classes.headingGrid}>
          <Typography
            style={{ color: "grey", fontSize: 20, fontWeight: "bold" }}
          >
            TRANSFER EMPLOYEE
          </Typography>
        </Grid>
        <Grid className={classes.typographyGrid}>
          <Typography
            style={{
              color: "grey",
              fontWeight: "bold",
              fontSize: 20,
              width: "50%",
              marginLeft: "15px",
            }}
          >
            From
          </Typography>
          <Typography
            style={{
              color: "grey",
              fontWeight: "bold",
              fontSize: 20,
              alignSelf: "left",
              width: "50%",
            }}
          >
            To
          </Typography>
        </Grid>
        <CardContent>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid className={classes.main} container spacing={3}>
              {/* Previous Disable Dealer Ship Valued  */}
              <Grid item xs={6}>
                <Autocomplete
                  options={dealerships || []}
                  getOptionLabel={(option) => option?.name}
                  value={
                    dealerships?.find(
                      (option) => option.name === formik.values.oldDealership
                    ) || null
                  }
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="oldDealership"
                      size="small"
                      label="Dealership"
                    />
                  )}
                />
              </Grid>
              {/* Select New Dealer Ship Valued  */}
              <Grid item xs={6}>
                <Autocomplete
                  options={dealerships || []}
                  getOptionLabel={(option) => option?.name}
                  value={
                    dealerships?.find(
                      (option) => option.name === formik.values.newDealership
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      formik.setValues({
                        ...formik.values,
                        newDealership: newValue.name,
                      });
                    } else {
                      formik.setValues({
                        ...formik.values,
                        newDealership: "",
                      });
                    }
                  }}
                  // onChange={(event, newValue) => {
                  //   formik.setFieldValue("newDealership", newValue?.name || "");
                  // }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="newDealership"
                      size="small"
                      label="Dealership"
                      error={
                        formik.touched.newDealership &&
                        Boolean(formik.errors.newDealership)
                      }
                      helperText={
                        formik.touched.newDealership &&
                        formik.errors.newDealership
                      }
                    />
                  )}
                />
              </Grid>
              {/* Previous Department Auto Complete */}
              <Grid item xs={6}>
                <Autocomplete
                  options={departments || []}
                  getOptionLabel={(option) => option?.name}
                  value={
                    departments?.find(
                      (option) => option.name === formik.values.oldDepartment
                    ) || null
                  }
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="oldDepartment"
                      size="small"
                      label="Department"
                    />
                  )}
                />
              </Grid>
              {/* Select New Department Auto Complete */}
              <Grid item xs={6}>
                <Autocomplete
                  options={departments || []}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("newDepartment", newValue?.name || "");
                  }}
                  value={
                    departments?.find(
                      (option) => option.name === formik.values.newDepartment
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="newDepartment"
                      size="small"
                      label="Department"
                      error={
                        formik.touched.newDepartment &&
                        Boolean(formik.errors.newDepartment)
                      }
                      helperText={
                        formik.touched.newDepartment &&
                        formik.errors.newDepartment
                      }
                    />
                  )}
                />
              </Grid>
              {/* Full Name Text Field */}
              <Grid item xs={6}>
                <TextField
                  name="fullName"
                  label="Full Name"
                  type="text"
                  value={formik.values.fullName}
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled
                />
              </Grid>
              {/*  Transfer Date Picker */}
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="Transfer Date"
                    value={formik.values.transferDate}
                    onChange={(date) =>
                      formik.setFieldValue("transferDate", date)
                    }
                    onBlur={() => formik.setFieldTouched("transferDate", true)}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                        error:
                          formik.touched.transferDate &&
                          Boolean(formik.errors.transferDate),
                        helperText:
                          formik.touched.transferDate &&
                          formik.errors.transferDate,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              {/* Current Postion Text Field  */}
              <Grid item xs={6}>
                <TextField
                  name="currentPostion"
                  label="Current Position"
                  type="text"
                  value={formik.values.currentPostion}
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled
                />
              </Grid>
              {/* Position Text Field */}
              <Grid item xs={6}>
                <TextField
                  name="newPosition"
                  label="New Position Title"
                  type="text"
                  value={formik.values.newPosition}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.newPosition &&
                    Boolean(formik.errors.newPosition)
                  }
                  helperText={
                    formik.touched.newPosition && formik.errors.newPosition
                  }
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              </Grid>

              {/* Additional Notes */}
              <Grid item xs={12}>
                <Typography>
                  Please provide additional information about the employee in
                  the box below such as accounts he/she is going to need access
                  to and which accounts needs to have access revoked
                </Typography>
                <TextField
                  name="additionalNotes"
                  label="Additional Notes"
                  type="text"
                  multiline
                  rows={5}
                  value={formik.values.additionalNotes}
                  onChange={formik.handleChange}
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              </Grid>

              {/* User Email Text Field */}
              <Grid item xs={6}>
                <TextField
                  name="userEmail"
                  label="Your Email"
                  type="email"
                  value={user?.email}
                  fullWidth
                  disabled
                  size="small"
                />
              </Grid>

              {/* Username Text Field */}
              <Grid item xs={6}>
                <TextField
                  label="Signed By"
                  type="text"
                  name="singnedBy"
                  value={user?.name}
                  disabled
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              </Grid>
              {/* Buttons */}
              <Grid container className={classes.buttonContainer}>
                <Grid item>
                  <Button
                    style={{ marginRight: "10px" }}
                    variant="contained"
                    color="inherit"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EmployeeTransferForm;
