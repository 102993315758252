import { Grid, Button } from "@mui/material";
import React from "react";
import { TagsList, DialogForm } from "./components";

import TagsApi from "../../services/APIs/Tags.Api";
import { useQuery } from "react-query";

const TagsManagement = () => {
  //   Get Tags ..
  const { data: tagsList, refetch: refetchTags } = useQuery(
    "GET_ALL_TAGS",
    () => TagsApi.getCommentTags()
  );

  return (
    // Main Grid
    <Grid>
      {/* Tag List Grid */}
      <Grid>
        <TagsList data={tagsList} refetch={refetchTags} />
      </Grid>
    </Grid>
  );
};

export default TagsManagement;
