import React, { useState } from "react";
import MUIDataTable from "mui-datatables";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";

import DialogForm from "../DialogForm/DialogForm";

import { ConfirmDialog } from "../../../../components";

import dynamicStyle from "./styles";
import TagsApi from "../../../../services/APIs/Tags.Api";

const TagsList = (props) => {
  const { data, refetch } = props;

  const classes = dynamicStyle();

  const [openDialog, setOpenDialog] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [tagID, setTagID] = useState(null);

  const hadnleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Open Delete Dialog ..
  const handleConfirmOpen = (id) => {
    setTagID(id);
    setConfirmOpen(true);
  };

  const handleOpenUpdateDialog = (id) => {
    setTagID(id);
    setOpenDialog(true);
  };

  //   Mui Table Columns ..
  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "tagName",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },

    {
      name: "color",
      label: "Color",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const tagColor = tableMeta.rowData[2];
          return (
            <>
              <Button
                variant="contained"
                sx={{
                  background: tagColor,
                  width: 100,
                  height: 20,
                }}
              ></Button>
            </>
          );
        },
      },
    },

    // Actions  ...
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <IconButton
                onClick={() => {
                  handleConfirmOpen(id);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleOpenUpdateDialog(id);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  //   Mui Table Options ..
  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    // responsive,
    selectableRows: "none",

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleDelete = async () => {
    const response = await TagsApi.deleteTag(tagID);
    if (response.success) {
      toast.success(response.message);
      setConfirmOpen(false);
      refetch();
    } else {
      toast.error("Employee not deleted!");
    }
  };

  return (
    // Main Grid
    <Grid>
      {/* Button Grid */}
      <Grid
        container
        justifyContent="space-between"
        className={classes.buttonGrid}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={hadnleOpenDialog}
          >
            ADD New Tag
          </Button>
        </Grid>
      </Grid>
      {/* Mui Table Gri */}
      <Grid style={{ marginTop: 20 }}>
        <MUIDataTable
          title={"Tags list"}
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>

      {/* Create and Update Tag  Dialog*/}
      <DialogForm
        open={openDialog}
        setOpen={setOpenDialog}
        refetchTags={refetch}
        singleTagID={tagID}
        setSingleTagID={setTagID}
      />
      {/* Delete Confirm Dialog  */}

      <Grid>
        {/* Delete Confirm Dialog  */}
        <ConfirmDialog
          title="Delete Tag ?"
          dialogContext="Are you sure to delete tag"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleDelete}
          setSingleTagID={setTagID}
        />
      </Grid>
    </Grid>
  );
};

export default TagsList;
