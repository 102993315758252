import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  buttonsGrid: {
    marginTop: "10px",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  deleteIcon: {
    color: theme.palette.red,
  },
  div: {
    cursor: "pointer",
  },
}));

export default dynamicStyle;
