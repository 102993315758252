import { Card, Grid, Typography, CardHeader, CardContent } from "@mui/material";
import React from "react";

import dynamicStyle from "./styles";

const EmployeeCard = (props) => {
  const { value, title } = props;

  const classes = dynamicStyle();
  return (
    <Card
      style={{
        borderRadius: 18,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      }}
    >
      <CardContent>
        <Grid className={classes.cardGrid}>
          <Typography variant="h6">{title}</Typography>
          <Typography className={classes.cardTypography} variant="h5">
            {value}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EmployeeCard;
