import {
  ALL_USERS,
  CREATE_USER,
  DELETE_USER,
  GET_MANAGERS,
  GET_SINGEL_USER,
  GET_USERS_FOR_FILTERS,
  GET_USER_LOGS,
  SING_IN,
  SING_UP,
  UPDATE_USER,
} from "../ApiConstants";
import { api } from "../../utils";

class AuthApi {
  static sharedIstance = new AuthApi();

  constructor() {
    if (AuthApi.sharedIstance != null) {
      return AuthApi.sharedIstance;
    }
  }

  //   Signup User  ...
  async singUP(body) {
    try {
      const response = await api.post(SING_UP, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  //   SignIN User  ...
  async singIn(body) {
    try {
      const response = await api.post(SING_IN, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
  // Get All Users.
  async getAllUsers() {
    try {
      const response = await api.get(ALL_USERS);
      const { success, data } = response.data;
      if (success) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get users for filters options ..
  async getUsersForFilters() {
    try {
      const response = await api.get(GET_USERS_FOR_FILTERS);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
  // Create New User ...
  async createUser(body) {
    try {
      const response = await api.post(CREATE_USER, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Delete User  ...
  async deleteUser(id) {
    try {
      const response = await api.delete(`${DELETE_USER}${id}`);
      const { success, message, data } = response.data;
      if (success) {
        return response.data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get single user  ...
  async getSingleUser(id) {
    try {
      const response = await api.get(`${GET_SINGEL_USER}${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Update User .
  async updateUser(id, body) {
    try {
      const response = await api.put(`${UPDATE_USER}${id}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Get user logs ..
  async getUserLogs(body) {
    try {
      const response = await api.post(`${GET_USER_LOGS}`, body);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get managers ..
  async getManagers() {
    try {
      const response = await api.get(`${GET_MANAGERS}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
}

export default AuthApi.sharedIstance;
