import { api } from "../../utils";
import {
  CREATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE,
  GET_EVENT_TYPES,
  GET_TEMPLATES_LIST,
  UPDATE_EMAIL_TEMPLATE,
} from "../ApiConstants";

class EmailTemplateApi {
  static sharedInstance = new EmailTemplateApi();

  constructor() {
    if (EmailTemplateApi.sharedInstance != null) {
      return EmailTemplateApi.sharedInstance;
    }
  }

  //   Get event types ..
  async getEvetTypes() {
    try {
      const response = await api.get(GET_EVENT_TYPES);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
  // Create Email Template ...
  async createEmailTemplate(body) {
    try {
      const response = await api.post(`${CREATE_EMAIL_TEMPLATE}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Get templates list ..
  async getTemplatesList() {
    try {
      const response = await api.get(`${GET_TEMPLATES_LIST}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Delete email template  ..
  async deleteTemplate(id) {
    try {
      const response = await api.delete(`${DELETE_EMAIL_TEMPLATE}${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Get email template by ID ..
  async getEmailTemplateByID(id) {
    try {
      const response = await api.get(`${GET_EMAIL_TEMPLATE}${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Update email template ..
  async updateEmailTemplate(body, id) {
    try {
      const response = await api.put(`${UPDATE_EMAIL_TEMPLATE}/${id}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
}

export default EmailTemplateApi.sharedInstance;
