import { Grid, Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import MUIDataTable from "mui-datatables";
import moment from "moment";

import SearchBox from "../../components/SearchBox/SearchBox";

import EmployeeApi from "../../services/APIs/Employee.Api";

import dynamicStyle from "./styles";

const TransferEmployeeList = () => {
  const navigate = useNavigate();

  const classes = dynamicStyle();

  const [query, setQuery] = useState("");
  const [search, setSearch] = useState(false);
  const [data, setData] = useState([]);

  // Handl Back  ...
  const handleBack = () => {
    navigate(-1);
  };

  const handleTransferEmployeeNavigate = (id, data) => {
    navigate(`/transfer-employee/${id}`, { state: data });
  };

  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "fullName",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "department",
      label: "Department",
    },

    {
      name: "dealership",
      label: "Dealership",
    },
    {
      name: "position",
      label: "Position",
    },

    {
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <Button
                variant="contained"
                color="inherit"
                onClick={() =>
                  handleTransferEmployeeNavigate(
                    tableMeta.rowData[0],
                    tableMeta.rowData
                  )
                }
              >
                Select
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
  };

  // Query Options
  // Get Employee with Search  ...
  const { data: employeeList, refetch } = useQuery(
    ["GEt_EMPLOYEE_BY_SEARCH", query],
    () => handleSearch(),

    {
      keepPreviousData: false,
    }
  );

  const handleSearch = async () => {
    if (query !== "") {
      const response = await EmployeeApi.searchEmployee(query);
      if (response?.data) {
        if (response?.data) {
          setData(response?.data);
        }
      }
    }
  };

  return (
    <Grid>
      <Grid className={classes.grid}>
        <Button
          startIcon={<ArrowBackIcon />}
          variant="contained"
          color="primary"
          onClick={handleBack}
        >
          Back
        </Button>
      </Grid>
      <SearchBox
        label="Search employee name or email"
        query={query}
        setQuery={setQuery}
        setSearch={setSearch}
        onSearch={handleSearch}
      />
      {data.length > 0 && (
        <Grid sx={{ my: 3 }}>
          <MUIDataTable
            title={"Employee list"}
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TransferEmployeeList;
