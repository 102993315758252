import * as yup from "yup";
import moment from "moment";

const employeeFormValidation = {
  firstName: "First name is required",
  startDate: "Start date is required",
  department: "Department is required",
  phone: "Phone is required",
  sin: "Sin is required",
  address: "Address is required",
  posatal: "Postal Code is required",
  email: "Email is required",
  employeeType: "Employee type is required",
  ssn: "SSN is required",
  zipCode: "Zip Code is required",

  // Type Errors  ...
  dateTypeError: "Provide valid date",
  emailTypeError: "Provde valid email",
};

export const employeeFormValidationSchema = yup.object().shape({
  firstName: yup.string().required(employeeFormValidation.firstName),
  startDate: yup
    .date()
    .typeError(employeeFormValidation.dateTypeError)
    .required(employeeFormValidation.startDate),
  phone: yup.string().required(employeeFormValidation.phone),
  postalCode: yup.string().when("dealershipCountry", {
    is: "CA",
    then: () => yup.string().required(employeeFormValidation.posatal),
  }),
  sin: yup.string().when("dealershipCountry", {
    is: "CA",
    then: () => yup.string().required(employeeFormValidation.sin),
  }),
  address: yup.string().required(employeeFormValidation.address),
  email: yup
    .string()
    .required(employeeFormValidation.email)
    .email(employeeFormValidation.emailTypeError),
  employeeType: yup.string().required(employeeFormValidation.employeeType),
  // If dealership country america then show error about ssn and zip code ..
  ssn: yup.string().when("dealershipCountry", {
    is: "USA",
    then: () => yup.string().required("SSN is required for America"),
  }),
  zipCode: yup.string().when("dealershipCountry", {
    is: "USA",
    then: () => yup.string().required("Zip Code is required for America"),
  }),
});

const singUpValidation = {
  userName: "User name is required",
  email: "Email is required",
  password: "Password is required",
  validEmail: "Provide a valid email",
};

export const signInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required(singUpValidation.email)
    .email(singUpValidation.validEmail),
  password: yup.string().required(singUpValidation.password),
});

export const signUpValidationSchema = yup.object().shape({
  userName: yup.string().required(singUpValidation.userName),
  email: yup
    .string()
    .required(singUpValidation.email)
    .email(singUpValidation.validEmail),
  // password: yup.string().required(singUpValidation.password),
});

// Creat User Validation  ...

const userValidation = {
  userName: "User name is required",
  email: "Email is required",
  role: "Role is required",
  validEmail: "Provide a valid email",
};

export const userValidationSchema = yup.object().shape({
  userName: yup.string().required(userValidation.userName),
  email: yup
    .string()
    .required(userValidation.email)
    .email(userValidation.validEmail),
  role: yup.string().required(userValidation.role),
});

// Transfer Employee Validations  ...
const transferEmployeeValidations = {
  newDealership: "Dealership is required",
  newDepartment: "Department is required",
  transferDate: "Transfer Date is reuqiured",
  newPosition: "New Postion is required",
  // Type Error ..
  dateTypeError: "Provide valid date",
};

export const transferEmployeeValidationSchema = yup.object().shape({
  newDealership: yup
    .string()
    .required(transferEmployeeValidations.newDealership),
  newDepartment: yup
    .string()
    .required(transferEmployeeValidations.newDepartment),
  transferDate: yup
    .date()
    .typeError(transferEmployeeValidations.dateTypeError)
    .required(transferEmployeeValidations.transferDate),
  newPosition: yup.string().required(transferEmployeeValidations.newPosition),
});

const tagValidation = {
  tagName: "Tag name is required",
  color: "Color is required",
};

export const tagValidationSchema = yup.object().shape({
  tagName: yup.string().required(tagValidation.tagName),
  color: yup.string().required(tagValidation.color),
});

// Email Template Validations

const emailTemplateValidation = {
  eventType: "Event type is required",
  templateName: "Template name is required",
  subject: "Subject is required",
  dealership: "Dealership is required",
  department: "Department is required",
  recipients: "Recipients is required",
  html: "HTML is required",
};

export const emailTemplateValidationSchema = yup.object().shape({
  eventType: yup.string().required(emailTemplateValidation.eventType),
  templateName: yup.string().required(emailTemplateValidation.templateName),
  subject: yup.string().required(emailTemplateValidation.subject),
  dealership: yup.string().required(emailTemplateValidation.dealership),
  department: yup.string().required(emailTemplateValidation.department),
  recipients: yup
    .array()
    .of(yup.string().email("Invalid email address"))
    .min(1, "At least one email is required"),
});

// Exit Employee Form Validation  ...

const exitEmployeeFormValidtion = {
  dateOfDeparture: "Date of departures is required",
  assignCustomersTo: "Assing customers to is required",
  reasonAndNotes: "Reason and notes is required",
  // Type Error ..
  dateTypeError: "Provide valid date",
  validEmail: "Provide a valid email",
};

export const exitEmployeeFormValidtionSchema = yup.object().shape({
  dateOfDeparture: yup
    .date()
    .typeError(exitEmployeeFormValidtion.dateTypeError)
    .required(exitEmployeeFormValidtion.dateOfDeparture),
  // emailForwardTo: yup
  //   .string()
  //   .required(exitEmployeeFormValidtion.emailForwardTo),

  reasonAndNotes: yup
    .string()
    .required(exitEmployeeFormValidtion.reasonAndNotes),
});

const employeeLeaveFormValidation = {
  leaveType: "Leave type is required",
  effectiveDate: "Effective date is required",
  returnToWorkDate: "Return to work date required",
  dateTypeError: "Provide valid date",
};

export const employeeLeaveFormValidationSchema = yup.object().shape({
  leaveType: yup.string().required(employeeLeaveFormValidation.leaveType),
  effectiveDate: yup
    .date()
    .typeError(employeeLeaveFormValidation.dateTypeError)
    .required(employeeLeaveFormValidation.effectiveDate)
    .test(
      "is-before-return-date",
      "Please make sure Effective Date is the day layoff/leave starts",
      function (value) {
        const { returnToWorkDate } = this.parent;
        return moment(value).isBefore(moment(returnToWorkDate), "day");
      }
    ),
  returnToWorkDate: yup
    .date()
    .typeError(employeeLeaveFormValidation.date)
    .required(employeeLeaveFormValidation.returnToWorkDate),
});
