import React from "react";
import {
  Card,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  CardContent,
} from "@mui/material";

const CustomCard = ({ title, data, columns }) => {
  return (
    <Grid item xs={12} md={6}>
      <Card
        style={{
          borderRadius: 18,
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        }}
      >
        <CardHeader title={title}></CardHeader>
        <CardContent>
          {data?.length === 0 ? (
            <Grid container justifyContent={"center"}>
              <small style={{ color: "grey" }}>Nothing for now.</small>
            </Grid>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: 15 }}
                      key={index}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data?.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        {Object.values(item).map((value, index) => (
                          <TableCell key={index}>{value}</TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CustomCard;
