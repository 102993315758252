import { Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import React from "react";

const SearchBox = ({ label, onSearch, setSearch, setQuery, query }) => {
  const handleQueryChange = (e) => {
    e.preventDefault();
    const newQ = e.target.value;
    if (newQ.length > 0) {
      setSearch(true);
      setQuery(e.target.value);
    } else {
      setSearch(false);
      setQuery(null);
    }
    onSearch();
  };
  return (
    <Grid>
      <TextField
        fullWidth
        type="search"
        label={label}
        value={query}
        variant="standard"
        onChange={handleQueryChange}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
        }}
      />
    </Grid>
  );
};

export default SearchBox;
