import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  Chip,
  TextField,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import { toast } from "react-toastify";
import moment from "moment";
import FileDownload from "js-file-download";

import TagsApi from "../../services/APIs/Tags.Api";
import EmployeeApi from "../../services/APIs/Employee.Api";
import AuthApi from "../../services/APIs/Auth.Api";
import { Filters } from "./components";
import SearchBox from "../../components/SearchBox/SearchBox";
import { Spinner } from "../../components";
import { FilterCard } from "./components";

import dynamicStyle from "./styles";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const initialValues = {
  comment: "",
  tag: "",
};
const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginTop: 8,
    marginBottom: 8,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const CreateFile = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const classes = dynamicStyle();

  const [selectedTags, setSelectedTags] = useState();
  const [files, setFiles] = React.useState([]);
  const [removedAttachment, setRemovedAttachments] = React.useState([]);
  const [employeeFiles, setEmployeeFiles] = useState([]);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    createdBy: null,
    tag: null,
    createdDateStart: null,
    createdDateEnd: null,
  });

  const { data, refetch: refetchFiles } = useQuery(
    [
      "GET_EMPLOYEE_FILES",
      filters.createdBy,
      filters.tag,
      offset,
      filters.createdDateStart && filters.createdDateEnd,
      query,
    ],
    () => getEmployeeFiles(),
    {
      enabled: !!id,
    }
  );

  // Get Employees Files Method  ...
  const getEmployeeFiles = async () => {
    if (search) {
      console.log("Search ..");
    } else {
      const response = await EmployeeApi.getEmployeeFile(id, {
        createdBy: filters.createdBy,
        tag: filters.tag,
        offset: offset,
        createdDateStart: filters.createdDateStart,
        createdDateEnd: filters.createdDateEnd,
      });
      setEmployeeFiles((prevFiles) => {
        if (offset === 0) {
          return response;
        } else {
          return [...prevFiles, ...response];
        }
      });
    }
  };

  const handleSearch = async () => {
    if (query !== "") {
      const response = await EmployeeApi.searchEmployeeFile(query, id);
      setEmployeeFiles(response);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: contractValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (body) => {
    const formData = new FormData();
    // Add the form data to the FormData object
    formData.append("employeeID", id);
    formData.append("comment", body.comment);
    formData.append("tag", body.tag);
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("attachments", files[i]);
      }
    }

    const response = await EmployeeApi.createEmployeeFile(formData);

    if (response.success) {
      toast.success(response.message);
      setSelectedTags(null);
      refetchFiles();
      formik.resetForm();
      setFiles([]);
    } else {
      toast.error("Employee file not added!");
    }
  };

  const { data: tagsList } = useQuery("GET_ALL_TAGS", () =>
    TagsApi.getCommentTags()
  );

  // Get users list for filters ..
  const { data: userList } = useQuery("GET_ALL_USERS", () =>
    AuthApi.getUsersForFilters()
  );

  const handleBack = () => {
    navigate(-1);
  };

  // ================
  // Open file Input
  // ================
  const handleClick = () => {
    // Show the file input
    const fileInput = document.getElementById("file-input");
    fileInput.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const fileArray = [];

    // Loop through the selected files and add them to the file array
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      fileArray.push(file);
    }
    setFiles(fileArray);
  };

  // ================================
  // Hanlde Remove Files from files
  // ================================
  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    const removedFile = newFiles.splice(index, 1)[0];
    setFiles(newFiles);
    // keep track removed files ...
    setRemovedAttachments([...removedAttachment, removedFile]);
  };

  // Const Hanlde add more

  const handleAddMore = () => {
    var fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.multiple = true;
    fileInput.onchange = function (event) {
      var newFiles = event.target.files;
      for (var i = 0; i < newFiles.length; i++) {
        files.push(newFiles[i]);
      }
      console.log(files);
    };
    fileInput.click();
  };

  //   Set Tag ..
  const handleClickTag = (data) => {
    setSelectedTags(data);
    formik.setFieldValue("tag", data);
  };

  const handleDrawerOpen = () => {
    setFilterOpen(true);
  };

  // Handle Filter ...
  const handleFilter = (data) => {
    setFilters({ ...filters, ...data });
  };

  const handleFileDownload = async (fileName, pathEmail) => {
    const response = await EmployeeApi.downloadFile({
      name: fileName,
      email: pathEmail,
    });
    FileDownload(response.data, fileName);
  };

  // ====================
  // Handle Scroll End
  // ====================
  const handleScrollEnd = () => {
    setLoading(true);
    setTimeout(() => {
      setOffset((prev) => prev + 20);
      setLoading(false);
    }, 3000);
  };

  const handleScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        handleScrollEnd();
      }

      //When Scroll reached to top ...
      if (document.documentElement.scrollTop === 0) {
        setOffset(0);
      }
    } catch (error) {
      console.log("scroller catch error ===>", error);
    }
  };

  // Scroll Hook
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "90vh" }}
    >
      {/* Showing spinner on scroll */}
      <Spinner open={loading} loading={loading} size={60} />
      <div style={{ flexGrow: 1 }}>
        <Grid container className={classes.buttonGrid}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
          <Grid item flexDirection="row">
            <Grid item lg={12} sm={12} xs={6}>
              <Button
                style={{ marginLeft: "auto" }}
                color="primary"
                size="medium"
                variant="outlined"
                startIcon={<FilterListIcon />}
                onClick={handleDrawerOpen}
              >
                Show filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Filters values Card */}

        <FilterCard filters={filters} setFilters={setFilters} />

        {/* Search Box*/}
        <Grid style={{ marginLeft: 16 }}>
          <SearchBox
            label="Search  file by tag or created by"
            query={query}
            setQuery={setQuery}
            setSearch={setSearch}
            onSearch={handleSearch}
          />
        </Grid>
        <div style={{ padding: "16px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              padding: "8px",
              marginBottom: "8px",
            }}
          >
            {tagsList &&
              tagsList.map((data) => {
                const isSelected = selectedTags?.includes(data?.tagName);
                const chipColor = isSelected ? `${data.color}` : "#fafafa";
                const contrastText = isSelected ? "#fafafa" : "#000000";

                return (
                  <Chip
                    key={data?.ID}
                    label={data?.tagName}
                    onClick={() => handleClickTag(data?.tagName)}
                    style={{
                      marginBottom: "4px",
                      backgroundColor: chipColor,
                      color: contrastText,
                    }}
                    size="small"
                    variant={isSelected ? "contained" : "outlined"}
                    icon={
                      isSelected ? (
                        <DoneIcon fontSize="small" color={chipColor} />
                      ) : null
                    }
                  />
                );
              })}
          </div>

          {/* Comment And File Section */}
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item md={10}>
                <TextField
                  name="comment"
                  label="Comment"
                  type="text"
                  fullWidth
                  size="small"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={0.7}>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  <AttachFileIcon />
                </IconButton>
                <input
                  id="file-input"
                  name="attachments"
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
              </Grid>
              <Grid item md={1}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon fontSize="small" />}
                  type="submit"
                  disabled={formik.values.comment === "" || !formik.values.tag}
                >
                  Send
                </Button>
              </Grid>
            </Grid>

            {/* Selected Files List */}
            {files.map((item, index) => (
              <Grid
                key={index}
                container
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "4px",
                  marginTop: "8px",
                }}
              >
                <Typography style={{ color: "grey" }}>{item.name}</Typography>
                <IconButton onClick={() => handleRemoveFile(index)}>
                  <ClearIcon fontSize="small" style={{ color: "red" }} />
                </IconButton>
              </Grid>
            ))}
            {files.length > 0 && (
              <Button
                color="inherit"
                variant="contained"
                style={{ marginTop: "8px" }}
                onClick={() => handleAddMore()}
              >
                Add More
              </Button>
            )}
          </form>
        </div>
        <Grid container spacing={2}>
          {employeeFiles &&
            employeeFiles?.map((dt, index) => (
              <Grid item xs={12} md={6} key={index}>
                <CommentCard
                  comment={dt}
                  files={dt?.attachment}
                  handleFileClick={(filename, pathEmail) =>
                    handleFileDownload(filename, pathEmail)
                  }
                />
              </Grid>
            ))}
        </Grid>
      </div>

      {/* Filters Options */}
      <Filters
        open={filterOpen}
        setOpen={setFilterOpen}
        tags={tagsList}
        users={userList}
        filters={filters}
        setFilters={setFilters}
        onFilter={handleFilter}
      />
    </div>
  );
};

function CommentCard({ comment, files, handleFileClick }) {
  const classes = useStyles();

  // using moment.js to parse and format date and time
  const date = moment(comment.createdDate).format("YYYY-MM-DD");
  const time = moment(comment.createdDate).format("HH:mm");

  const backgroundColor = comment?.color;
  const textColor = "#fafafa";

  return (
    <Card className={classes.card}>
      <CardContent>
        <Chip
          label={comment.tag}
          sx={{ color: textColor, background: backgroundColor }}
        />
        {/* Tag */}
        <Typography variant="h5" component="h2">
          {comment.comment}
        </Typography>
        <Box display="flex" justifyContent="start" alignItems="center">
          <Typography className={classes.pos} color="textSecondary">
            {comment.userName}
          </Typography>
          <div style={{ marginLeft: 8 }}>{" - "}</div>
          <Typography
            style={{ marginLeft: 8 }}
            className={classes.pos}
            color="textSecondary"
          >
            {date} at {time}
          </Typography>
        </Box>
        <Typography variant="body2" component="p">
          {files.map(
            (file, index) => (
              <Link
                component="button"
                variant="body2"
                onClick={() => handleFileClick(file.name, file.email)}
                key={index}
              >
                {file.name}
              </Link>
            ) // Attached File Names
          )}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CreateFile;
