import React from "react";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-query";
import { Grid } from "@mui/material";
import moment from "moment";

import LeaveOfAbsenceApi from "../../services/APIs/LeaveOfAbsence.Api";

const EmployeeOnLeaveList = () => {
  // Get employee leaves ..

  const { data: employeeLeaves } = useQuery("GET_EMPLOYEE_LEAVES_DATA", () =>
    LeaveOfAbsenceApi.getEmployeeLeaves()
  );

  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "fullName",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "leaveType",
      label: "Leave Type",
    },
    {
      name: "effectiveDate",
      label: "Leave Date",
      options: {
        customBodyRender: (value) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "returnToWorkDate",
      label: "Return To Work Date",
      options: {
        customBodyRender: (value) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
  ];

  //   Mui Table Options ..
  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    // responsive,
    selectableRows: "none",

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  return (
    <Grid>
      <MUIDataTable
        title="Employee Leaves Of Absence List"
        data={employeeLeaves}
        columns={columns}
        options={options}
      />
    </Grid>
  );
};

export default EmployeeOnLeaveList;
