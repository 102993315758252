import { api } from "../../utils";
import {
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_EMPLOYEES,
  GET_SINGLE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  SEARCH_EMPLOYEE,
  CREATE_TRANSFER_EMPLOYEE,
  GET_TRANSFER_EMPLOYEES,
  CREATE_DRAFT,
  UPDATE_DRAFT,
  GET_EMPLOYEES_DS_DPT,
  CREATE_FILE,
  GET_EMPLOYEE_FILE,
  SEARCH_EMPLOYEE_FILE,
  DOWNLOAD_FILE,
  DELETE_DRAFT,
  CREATE_REMOVAL_EMPLOYEE,
  GET_REMOVAL_EMPLOYEES,
  UPDATE_REMOVAL_EMPLOYEE,
  GET_EMPLOYEES_FORDD,
  MANAGERS_EMPLOYEES,
  CANCEL_EMPLOYEE_REMOVAL,
  GET_EMPLOYEES_EMAILS,
  CHECK_SIN,
  CHECK_SIN_ON_UPDATE,
} from "../ApiConstants";

class EmployeeApi {
  static sharedInstance = new EmployeeApi();

  constructor() {
    if (EmployeeApi.sharedInstance != null) {
      return EmployeeApi.sharedInstance;
    }
  }

  async getEmployees(draft) {
    try {
      const response = await api.get(`${GET_EMPLOYEES}?draft=${draft}`);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  async getManagersFromAD() {
    try {
      const response = await api.get(`employee/ad_managers`);

      return [response.data, null];
    } catch (error) {
      console.log(error);
      return [null, error];
    }
  }

  // Get Employees based on dealership and deaprtment
  async getEmployeesByDealershipsAndDept(draft) {
    try {
      const response = await api.get(`${GET_EMPLOYEES_DS_DPT}?draft=${draft}`);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  async createEmloyee(body) {
    try {
      const response = await api.post(CREATE_EMPLOYEE, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Delete employee
  async deleteEmployee(id) {
    try {
      const response = await api.delete(`${DELETE_EMPLOYEE}/${id}`);
      const { success, message, data } = response.data;
      if (success) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      return error;
    }
  }

  // Delete Draft  ..
  async deleteDraft(id) {
    try {
      const response = await api.delete(`${DELETE_DRAFT}/${id}`);
      const { success, message, data } = response.data;
      if (success) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      return error;
    }
  }

  // Get single employee
  async getEmployeeByID(id) {
    try {
      const response = await api.get(`${GET_SINGLE_EMPLOYEE}/${id}`);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  // Update Employee  ..
  async updateEmployee(body, id) {
    try {
      const response = await api.put(`${UPDATE_EMPLOYEE}/${id}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Search Employee  ...
  async searchEmployee(query) {
    try {
      const response = await api.post(`${SEARCH_EMPLOYEE}?query=${query}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Create Transfer Employee ..
  async createTransferEmployee(body) {
    try {
      const response = await api.post(CREATE_TRANSFER_EMPLOYEE, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Get Transfer Employee List ..
  async getTransferEmployees() {
    try {
      const response = await api.get(GET_TRANSFER_EMPLOYEES);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Create Draft ...
  async createDraft(body) {
    try {
      const response = await api.post(CREATE_DRAFT, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Update Employee  ..
  async updateDraft(body, id) {
    try {
      const response = await api.put(`${UPDATE_DRAFT}/${id}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Create Employee File  ...
  async createEmployeeFile(body) {
    try {
      const response = await api.post(CREATE_FILE, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, data } = response.data;
      if (success) {
        return response.data;
      }
      console.log("resposne", response);
    } catch (error) {
      return error?.response?.data;
    }
  }

  // Get Employee file....

  async getEmployeeFile(id, body) {
    try {
      const response = await api.post(`${GET_EMPLOYEE_FILE}/${id}`, body);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Search Employee File ...
  async searchEmployeeFile(query, id) {
    try {
      const response = await api.post(
        `${SEARCH_EMPLOYEE_FILE}/${id}?query=${query}`
      );
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Download File ..
  async downloadFile(body) {
    try {
      const response = await api.post(DOWNLOAD_FILE, body, {
        responseType: "blob",
      });
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Create Employee Removal Request ...
  async createEmployeeRemoval(body) {
    try {
      const response = await api.post(CREATE_REMOVAL_EMPLOYEE, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Get removal employees list  ..
  async getRemovalEmployees() {
    try {
      const response = await api.get(GET_REMOVAL_EMPLOYEES);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get removal by  ID ..
  async getRemovalEmployeeByID(id) {
    try {
      const response = await api.get(`${GET_REMOVAL_EMPLOYEES}/${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // update removal employee

  async updateRemovalEmployee(id, body) {
    try {
      const response = await api.put(`${UPDATE_REMOVAL_EMPLOYEE}/${id}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async getEmployeeForDD() {
    try {
      const response = await api.get(`${GET_EMPLOYEES_FORDD}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get employee by managers ..
  async getEmployeeByManagers() {
    try {
      const response = await api.get(`${MANAGERS_EMPLOYEES}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Cancel employee removal ..
  async cancelEmployeeRemoval(id) {
    try {
      const response = await api.delete(`${CANCEL_EMPLOYEE_REMOVAL}/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Get employees  emails by dealership ..
  async getEmployeesEmailsByDealership(body) {
    try {
      const response = await api.post(`${GET_EMPLOYEES_EMAILS}`, body);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
  // Check sin exists or not  ..
  async checkSinExists(body) {
    try {
      const response = await api.post(`${CHECK_SIN}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Check sin on update employee..
  async checkSinOnUpdateEmployee(body, empID) {
    try {
      const response = await api.post(`${CHECK_SIN_ON_UPDATE}/${empID}`, body);
      console.log("sin check response.data", response.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
}

export default EmployeeApi.sharedInstance;
