import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, Typography, Card, CardContent } from "@mui/material";

const FilterCard = ({ filters, setFilters }) => {
  const handelClearFilter = () => {
    setFilters({
      tag: null,
      createdBy: null,
      createdDateStart: null,
      createdDateEnd: null,
    });
  };

  return (
    <>
      {filters.createdBy ||
      filters.tag ||
      (filters.createdDateStart && filters.createdDateEnd) ? (
        <Grid>
          <Card sx={{ width: 400 }}>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 0px 20px 10px",
              }}
            >
              <Typography style={{ color: "grey", fontSize: 20 }}>
                Filter
              </Typography>
              <Typography style={{ cursor: "pointer" }}>
                <CancelIcon fontSize="small" onClick={handelClearFilter} />
              </Typography>
            </Grid>

            <CardContent>
              {filters.createdDateStart && filters.createdDateEnd ? (
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={{ color: "grey" }}>
                    Start Date: {filters.createdDateStart}
                  </Typography>
                  <Typography style={{ color: "grey" }}>
                    End Date: {filters.createdDateEnd}
                  </Typography>
                </Grid>
              ) : null}

              {filters.tag || filters.createdBy ? (
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {filters.tag !== null && (
                    <Typography style={{ color: "grey" }}>
                      Tag: {filters.tag}
                    </Typography>
                  )}
                  {filters.createdBy !== null && (
                    <Typography style={{ color: "grey" }}>
                      Created By: {filters.createdBy}
                    </Typography>
                  )}
                </Grid>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      ) : null}
    </>
  );
};

export default FilterCard;
