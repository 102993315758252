import React, { useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";

import { ConfirmDialog } from "../../components";
import { ListDialog } from "./components";

import AuthApi from "../../services/APIs/Auth.Api";

import dynamicStyle from "./styles";

const UserList = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const classes = dynamicStyle(theme);

  const [userID, setUserID] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [ListDialogData, setListDialogData] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("");

  // Get Users  ..
  const { data: users, refetch: refetchUsers } = useQuery("ALL_USERS", () =>
    AuthApi.getAllUsers()
  );

  const setDialogData = (values, title) => {
    if (values && values.length > 0) {
      setListDialogData(values);
      setDialogTitle(title);
      setOpen(true);
    }
  };

  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "userName",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "departments",
      label: "Departments",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let departments;
          if (
            tableMeta.rowData[5] === "Admin" ||
            tableMeta.rowData[5] === "General Manager"
          ) {
            departments = "All";
          } else {
            departments = value ? value.length : 0;
          }
          return (
            <div
              className={classes.div}
              onClick={() => {
                setDialogData(value, "Departments List");
              }}
            >
              {departments}
            </div>
          );
        },
      },
    },
    {
      name: "dealerships",
      label: "Dealerships",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let dealerships;
          if (tableMeta.rowData[5] === "Admin") {
            dealerships = "All";
          } else {
            dealerships = value ? value.length : 0;
          }
          return (
            <div
              className={classes.div}
              onClick={() => {
                setDialogData(value, "Dealerships List");
              }}
            >
              {dealerships}
            </div>
          );
        },
      },
    },
    {
      name: "role",
      label: "Type",
    },

    {
      name: "createDate",
      label: "Created Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <IconButton
                onClick={() => {
                  handleConfirmOpen(id);
                }}
              >
                <DeleteIcon className={classes.deleteIcon} />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleUpdateNavigate(id);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
  };

  const handleBack = () => {
    navigate("/");
  };

  // Handle Update Navigate  ..
  const handleUpdateNavigate = (id) => {
    navigate(`/user/update/${id}`);
  };

  const handleConfirmOpen = (id) => {
    setUserID(id);
    setConfirmOpen(true);
  };

  const handleDelete = async () => {
    const response = await AuthApi.deleteUser(userID);
    if (response.success) {
      toast.success(response.message);
      setConfirmOpen(false);
      refetchUsers();
    } else {
      toast.error("Employee not deleted!");
    }
  };
  return (
    // Main Grid
    <Grid>
      {/* Buttons Grid  */}
      <Grid container className={classes.buttonsGrid}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PersonAddIcon />}
            onClick={() => navigate("/add-user")}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
      {/* User Table Grid */}
      <Grid sx={{ my: 3 }}>
        {users && users.length > 0 && (
          <MUIDataTable
            title={"Users list"}
            data={users}
            columns={columns}
            options={options}
          />
        )}
      </Grid>
      {/* Delete Confirm Dialog  */}
      <Grid>
        <ConfirmDialog
          title="Delete User ?"
          dialogContext="Are you sure to delete user"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleDelete}
        />
      </Grid>
      {/* User Departments or Dealerships Dialog */}
      <Grid>
        <ListDialog
          open={open}
          setOpen={setOpen}
          data={ListDialogData}
          dialogTitle={dialogTitle}
        />
      </Grid>
    </Grid>
  );
};

export default UserList;
