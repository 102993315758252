import {
  Grid,
  Typography,
  TextField,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import moment from "moment";

import EmployeeApi from "../../services/APIs/Employee.Api";
import LeaveOfAbsenceApi from "../../services/APIs/LeaveOfAbsence.Api";
import LocalStorage from "../../managers/LocalStorage";
import { employeeLeaveFormValidationSchema } from "../../utils/validations";

import { EamilRadioSection, EmployeeRadioSection } from "./components";

import dynamicStyle from "./styles";

const initialValues = {
  fullName: "",
  email: "",
  dealership: "",
  department: "",
  leaveType: "",
  effectiveDate: null,
  returnToWorkDate: null,
  restrictEmployeeAccess: 0,
  employeeExistingAccess: 0,
  selectiveAccess: 0,
  keepEmailAccess: 0,
  userHaveNotEmail: 0,
  emailActiveWithOutAccess: 0,
  forwadEmailFollowingAddress: 0,
  forwardToEmail: "",
  allowCRMAccess: 0,
  allowOnlineTraining: 0,
  allowInFordAccess: 0,
  allowvAutoAcess: 0,
  notes: "",
  customLeaveType: "",
  // Name of forward to email employee ..
  forwardToName: "",
};

const EmployeeLeaveForm = ({ open, setOpen, id, setID, setEmployeeID }) => {
  const classes = dynamicStyle();

  const navigate = useNavigate();

  const [employeeData, setEmployeeDate] = useState([]);
  // Checkboxes checked states ..
  const [isCRMChecked, setIsCRMChecked] = useState(false);
  const [isInfordChecked, setIsInfordChecked] = useState(false);
  const [isTrainingChecked, setIsTrainingChecked] = useState(false);
  const [isvAutoAccessCheck, setIsvAutoAccessCheck] = useState(false);
  const [leaveDays, setLeaveDays] = useState("");
  const [employeeDealership, setEmployeeDealership] = useState(null);

  // State for selective access radio .
  const [isSelectiveChecked, setIsSelectiveChecked] = useState(false);
  // State for forward to email ..
  const [isforwardEmailChecked, setIsforwardEmailChecked] = useState(false);

  const user = LocalStorage.getUser();

  // Get leaveTypes ..
  const { data: leaveTypes } = useQuery("GET_LEAVE_OF_ABSENCE_TYPES", () =>
    LeaveOfAbsenceApi.getLeaveTypes()
  );

  // Get employees emails by dealerships ...
  const { data: employeeEmails } = useQuery(
    ["EMPLOYEE_EMAILS", employeeDealership],
    () =>
      EmployeeApi.getEmployeesEmailsByDealership({
        dealership: employeeDealership,
      }),
    {
      enabled: !!employeeDealership,
    }
  );

  // Create employee leave of absence ..
  const { mutate: createEmployeeLeave } = useMutation(
    (body) => LeaveOfAbsenceApi.createEmployeeLeave(body),
    {
      onSuccess: (res) => {
        if (res.success) {
          toast.success(res.message);
          handleCancel();
          navigate("/leaves");
        } else {
          toast.error(res.message);
        }
      },
      onError: (error) => toast.error(error.message),
    }
  );

  useEffect(() => {
    const getEmployee = async (id) => {
      const response = await EmployeeApi.getEmployeeByID(id);
      setEmployeeDate(response);
    };

    getEmployee(id);
  }, [id]);

  //   set employee details fields  ..
  useEffect(() => {
    if (employeeData !== null && id !== null) {
      formik.setFieldValue("fullName", employeeData[0]?.fullName);
      formik.setFieldValue("dealership", employeeData[0]?.dealership);
      formik.setFieldValue("department", employeeData[0]?.department);
      formik.setFieldValue("email", employeeData[0]?.email);
      // Setting employee dealership ..
      setEmployeeDealership(employeeData[0]?.dealership);
    }
  }, [employeeData]);

  console.log("Emp dealership ===", employeeDealership);

  // Formik ..
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: employeeLeaveFormValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  // Handle Submit
  const handleSubmit = async (body) => {
    createEmployeeLeave(body);
  };

  const handleCancel = () => {
    setOpen(false);
    setID(null);
    formik.resetForm();
    setIsCRMChecked(false);
    setIsInfordChecked(false);
    setIsTrainingChecked(false);
    setIsvAutoAccessCheck(false);
    setIsSelectiveChecked(false);
  };

  // Check box checked methods ..
  const handleCRMChecked = (event) => {
    setIsCRMChecked(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("allowCRMAccess", 1);
    } else {
      formik.setFieldValue("allowCRMAccess", 0);
    }
  };

  const handleInFordChecked = (event) => {
    setIsInfordChecked(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("allowInFordAccess", 1);
    } else {
      formik.setFieldValue("allowInFordAccess", 0);
    }
  };

  const handleOnlineTrainingChecked = (event) => {
    setIsTrainingChecked(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("allowOnlineTraining", 1);
    } else {
      formik.setFieldValue("allowOnlineTraining", 0);
    }
  };

  const handleVAutoChecked = (event) => {
    setIsvAutoAccessCheck(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("allowvAutoAcess", 1);
    } else {
      formik.setFieldValue("allowvAutoAcess", 0);
    }
  };

  // Handle Restric access ..
  const handleRadioChange = (accessType) => {
    const accessValues = {
      restrict: {
        restrictEmployeeAccess: 1,
        employeeExistingAccess: 0,
        selectiveAccess: 0,
      },
      existing: {
        restrictEmployeeAccess: 0,
        selectiveAccess: 0,
        employeeExistingAccess: 1,
      },
      selectiveaccess: {
        restrictEmployeeAccess: 0,
        selectiveAccess: 1,
        employeeExistingAccess: 0,
      },
      keepAccess: {
        keepEmailAccess: 1,
        emailActiveWithOutAccess: 0,
        forwadEmailFollowingAddress: 0,
        userHaveNotEmail: 0,
      },
      activeWithOutAccess: {
        keepEmailAccess: 0,
        emailActiveWithOutAccess: 1,
        forwadEmailFollowingAddress: 0,
        userHaveNotEmail: 0,
      },
      fowardToFollowing: {
        keepEmailAccess: 0,
        emailActiveWithOutAccess: 0,
        forwadEmailFollowingAddress: 1,
        userHaveNotEmail: 0,
      },
      userHaveEmail: {
        keepEmailAccess: 0,
        emailActiveWithOutAccess: 0,
        forwadEmailFollowingAddress: 0,
        userHaveNotEmail: 1,
      },
    };

    formik.setValues({
      ...formik.values,
      ...accessValues[accessType],
    });
  };

  const handleEffectiveDateChange = (val) => {
    formik.setFieldValue("effectiveDate", val);
    if (leaveDays > 0) {
      formik.setFieldValue("returnToWorkDate", val?.add(leaveDays, "days"));
    }
  };

  console.log(
    "name to ===>",
    formik.values.forwardToName,
    "Email to ==",
    formik.values.forwardToEmail
  );

  return (
    <Grid>
      <Dialog fullScreen open={open} onClose={handleCancel}>
        <Grid className={classes.dialogTitle}>
          <Typography sx={{ margin: 2.5, fontSize: 20 }}>
            Employee Leave form
          </Typography>

          <CloseIcon
            onClick={handleCancel}
            sx={{ margin: 2.5, color: "white", cursor: "pointer" }}
            fontSize="medium"
          />
        </Grid>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={2}>
              {/* Fullname text */}
              <Grid item xs={6} md={6}>
                <TextField
                  readOnly={true}
                  name="fullName"
                  label="Full Name"
                  type="text"
                  value={formik.values.fullName}
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              </Grid>
              {/* Dealership text */}
              <Grid item xs={6} md={6}>
                <TextField
                  readOnly={true}
                  name="dealership"
                  label="Dealership"
                  type="text"
                  value={formik.values.dealership}
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              </Grid>
              {/* Department text */}
              <Grid item xs={6} md={6}>
                <TextField
                  readOnly={true}
                  name="department"
                  label="Department"
                  type="text"
                  value={formik.values.department}
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              </Grid>
              {/* Leave Type Autocomplete  */}
              <Grid item xs={6} md={6}>
                <Autocomplete
                  options={leaveTypes || []}
                  getOptionLabel={(option) => option?.type}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setLeaveDays(newValue?.leaveDays || 0);

                      formik.setValues({
                        ...formik.values,
                        leaveType: newValue.type,
                        customLeaveType: "",
                      });
                    } else {
                      formik.setValues({
                        ...formik.values,
                        leaveType: "",
                      });
                    }
                  }}
                  value={
                    leaveTypes?.find(
                      (option) => option.type === formik.values.leaveType
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="leaveType"
                      size="small"
                      label="Leave Type"
                      error={
                        formik.touched.leaveType &&
                        Boolean(formik.errors.leaveType)
                      }
                      helperText={
                        formik.touched.leaveType && formik.errors.leaveType
                      }
                    />
                  )}
                />
                {/* If Leave type is Other then show Input field to type value in it  */}
                {formik?.values?.leaveType === "Other" && (
                  <>
                    <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                      <TextField
                        name="customLeaveType"
                        label="Other Leave Type"
                        type="text"
                        value={formik.values.customLeaveType}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              {/* Effective date */}
              <Grid item xs={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%", height: "5%" }}
                    label="Effective Date"
                    value={formik.values.effectiveDate}
                    onChange={(date) => {
                      handleEffectiveDateChange(date);
                    }}
                    onBlur={() => formik.setFieldTouched("effectiveDate", true)}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                        error:
                          formik.touched.effectiveDate &&
                          Boolean(formik.errors.effectiveDate),
                        helperText:
                          formik.touched.effectiveDate &&
                          formik.errors.effectiveDate,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              {/* Return to work date */}
              <Grid item xs={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%", height: "5%" }}
                    label="Return to work"
                    value={formik.values.returnToWorkDate}
                    onChange={(date) =>
                      formik.setFieldValue("returnToWorkDate", date)
                    }
                    onBlur={() =>
                      formik.setFieldTouched("returnToWorkDate", true)
                    }
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                        // error:
                        //   formik.touched.returnToWorkDate &&
                        //   Boolean(formik.errors.returnToWorkDate),
                        // helperText:
                        //   formik.touched.returnToWorkDate &&
                        //   formik.errors.returnToWorkDate,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              {/* Employee radio buttons section  */}
              <Grid item xs={6} container direction="column">
                <EmployeeRadioSection
                  formik={formik}
                  handleRadioChange={handleRadioChange}
                />
                {/* Check boxes Section */}
                {formik.values.selectiveAccess === 1 && (
                  <Grid>
                    {/* Allow CRM access check box */}
                    <Grid item>
                      <FormControl>
                        <FormLabel component="legend">
                          Selective Access
                        </FormLabel>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCRMChecked}
                              onChange={handleCRMChecked}
                              color="primary"
                            />
                          }
                          label="Allow CRM Access (PBS/DealerSocket)"
                        />
                      </FormControl>
                    </Grid>
                    {/* Allow online trainin check box */}
                    <Grid item>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isTrainingChecked}
                              onChange={handleOnlineTrainingChecked}
                              color="primary"
                            />
                          }
                          label="Allow online training (P2P/DealerConnect)"
                        />
                      </FormControl>
                    </Grid>
                    {/* Allow inFord / DealerConnect Access check box */}
                    <Grid item>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isInfordChecked}
                              onChange={handleInFordChecked}
                              color="primary"
                            />
                          }
                          label="Allow inFord / DealerConnect Access"
                        />
                      </FormControl>
                    </Grid>
                    {/* Allow vAuto Access check box */}
                    <Grid item>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isvAutoAccessCheck}
                              onChange={handleVAutoChecked}
                              color="primary"
                            />
                          }
                          label="Allow vAuto Access"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {/* What to do with email section  ... */}
              <Grid item xs={6} container direction="column">
                <EamilRadioSection
                  formik={formik}
                  handleRadioChange={handleRadioChange}
                  employeeEmails={employeeEmails}
                />
              </Grid>

              {/* Notes comment box  */}
              <Grid item xs={12}>
                <Typography sx={{ color: "gray", fontSize: 14 }}>
                  Notes / any other restrictions or access this user might need.
                  Please write it down in the box below
                </Typography>
                <TextField
                  type="text"
                  margin="dense"
                  name="notes"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  fullWidth
                  multiline
                  rows={5}
                  size="small"
                />
              </Grid>
              {/* Username text field */}
              <Grid item xs={6} md={6}>
                <TextField
                  readOnly={true}
                  name="userName"
                  label="Username"
                  type="text"
                  value={user?.name}
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              </Grid>
              {/* Useremail text field */}
              <Grid item xs={6} md={6}>
                <TextField
                  readOnly={true}
                  name="userEmail"
                  label="Email"
                  type="text"
                  value={user?.email}
                  // onChange={formik.handleChange}
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              </Grid>
              {/* Buttons container */}
              <Grid container sx={{ my: 2 }} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="inherit"
                    sx={{ mx: 2 }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </Grid>
  );
};

export default EmployeeLeaveForm;
