import { api } from "../../utils";
import {
  GET_EMANAGER_EMPLOYEE_LEAVES_ANALYTICS,
  GET_MANAGER_EMPLOYEE_ANALYTICS,
  GET_MANAGER_EMPLOYEE_ANNIVERSARIES,
  GET_MANAGER_EMPLOYEE_RETURN_TO_WORK,
  GET_MANAGER_REMOVAL_EMPLOYEES_ANALYTICS,
  GET_MANAGER_TRANSFER_EMPLOYEE_ANALYTICS,
} from "../ApiConstants";

class ManagerDashBoardApi {
  static sharedInstance = new ManagerDashBoardApi();

  constructor() {
    if (ManagerDashBoardApi.sharedInstance != null) {
      return ManagerDashBoardApi.sharedInstance;
    }
  }

  // Get employee dashboard analytics ..
  async getEmployeeAnalytics() {
    try {
      const response = await api.get(GET_MANAGER_EMPLOYEE_ANALYTICS);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get transfer employee dashboard analytics ..
  async getTransferEmployeeAnalytics() {
    try {
      const response = await api.get(GET_MANAGER_TRANSFER_EMPLOYEE_ANALYTICS);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
  // Get employee annivarseries dashboard analytics ..
  async getEmpolyeeAnniversaries() {
    try {
      const response = await api.get(GET_MANAGER_EMPLOYEE_ANNIVERSARIES);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get removal employee dashboard analytics ..
  async getRemovalEmployeeAnalytics() {
    try {
      const response = await api.get(GET_MANAGER_REMOVAL_EMPLOYEES_ANALYTICS);
      const { data, success } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  //   Get upcoming employees leaves anaylytics  ..
  async getEmployeesLeavesAnalytics() {
    try {
      const response = await api.get(
        `${GET_EMANAGER_EMPLOYEE_LEAVES_ANALYTICS}`
      );
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  //  Get upcoming employees return to work  ..
  async getEmployeesReturnToWork() {
    try {
      const response = await api.get(`${GET_MANAGER_EMPLOYEE_RETURN_TO_WORK}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
}

export default ManagerDashBoardApi.sharedInstance;
