import { api } from "../../utils";
import {
  GET_DEALERSSHIPS,
  GET_DEPARTMENTS,
  GET_USER_DEALERSHIPS,
  GET_USER_DEPARTMENTS,
} from "../ApiConstants";

class DealerShipApi {
  static sharedInstance = new DealerShipApi();

  constructor() {
    if (DealerShipApi.sharedInstance != null) {
      return DealerShipApi.sharedInstance;
    }
  }

  // Get All Dealerships ..
  async getDealersShips() {
    try {
      const response = await api.get(GET_DEALERSSHIPS);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  // Get User Dealerships  ...
  async getUserDealerships(id) {
    try {
      const response = await api.get(`${GET_USER_DEALERSHIPS}/${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Get All Departments  ....
  async getDepartements() {
    try {
      const response = await api.get(GET_DEPARTMENTS);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }
  // Get user departments  ..
  async getUserDepartmetns(id) {
    try {
      const response = await api.get(`${GET_USER_DEPARTMENTS}/${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
}

export default DealerShipApi.sharedInstance;
