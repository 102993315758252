import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, Grid, IconButton } from "@mui/material";

import EmployeeApi from "../../services/APIs/Employee.Api";
import { ConfirmDialog } from "../../components";

import dynamicStyle from "./styles";

const DraftList = () => {
  const classes = dynamicStyle();

  const navigate = useNavigate();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [employeeID, setEmployeeID] = useState(null);

  const { data, refetch } = useQuery(
    "GET_EMPLOYEES",
    () => EmployeeApi.getEmployeesByDealershipsAndDept(1) // 1 in param to get employee whose draft is 1
  );

  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "fullName",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },
    // For Known as ..
    {
      name: "knowsAs",
      label: "Knows As",
    },
    {
      name: "dealership",
      label: "Dealership",
    },
    {
      name: "department",
      label: "Department",
    },
    {
      name: "position",
      label: "Position",
    },

    {
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("YYYY-MM-DD");
        },
      },
    },
    // Actions  ...
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <IconButton
                onClick={() => {
                  handleConfirmOpen(id);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleUpdatNavigate(id);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    // responsive,
    selectableRows: "none",

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleConfirmOpen = (id) => {
    setEmployeeID(id);
    setConfirmOpen(true);
  };

  const handleDelete = async () => {
    const response = await EmployeeApi.deleteDraft(employeeID);
    if (response.success) {
      toast.success("Draft deleted successfully");
      setConfirmOpen(false);
      refetch();
    } else {
      toast.error("Draft not deleted!");
    }
  };

  // Handle update navigate  ...
  const handleUpdatNavigate = (id) => {
    navigate(`/update/${id}`);
  };

  return (
    <Grid>
      {/* Mui Data Table */}
      <Grid sx={{ my: 3 }}>
        <MUIDataTable
          title={"Draft list"}
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>

      {/* Delete Confirm Dialog  */}
      <ConfirmDialog
        title="Delete Draft ?"
        dialogContext="Are you sure to delete drft"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      />
    </Grid>
  );
};

export default DraftList;
