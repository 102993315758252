import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import { tagValidationSchema } from "../../../../utils/validations";
import TagsApi from "../../../../services/APIs/Tags.Api";

import dynamicStyle from "./styles";

const initialValues = {
  tagName: "",
  color: "",
};

const DialogForm = (props) => {
  const { open, setOpen, refetchTags, singleTagID, setSingleTagID } = props;

  const classes = dynamicStyle();

  const { data: singleTag } = useQuery(
    ["GET_SINGLE_TAG", singleTagID],
    () => TagsApi.getSingleTag(singleTagID),
    {
      enabled: !!singleTagID,
    }
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: tagValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  // Set form fields if update tag ..
  useEffect(() => {
    if (singleTag != undefined && singleTagID != null) {
      formik.setFieldValue("tagName", singleTag[0]?.tagName);
      formik.setFieldValue("color", singleTag[0]?.color);
    }
  }, [singleTag]);

  //  Create Tag   ...
  const { mutate: Tags, isLoading } = useMutation(
    (body) => {
      if (singleTagID !== null) {
        return TagsApi.updateTag(body, singleTagID);
      } else {
        return TagsApi.createTag(body);
      }
    },
    {
      onSuccess: (res) => {
        if (res.success) {
          toast.success(res.message);
          refetchTags();
          setOpen(false);
          setSingleTagID(null);
          formik.resetForm();
        } else {
          toast.error(res.message);
        }
      },
      onError: (error) => toast.error(error.message),
    }
  );

  const handleSubmit = (values) => {
    Tags(values);
  };

  const handleClose = () => {
    setSingleTagID(null);
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "430px",
          },
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {singleTagID != null ? "Update tag" : "Add new tag"}
      </DialogTitle>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <DialogContent className={classes.dialogContent}>
          <TextField
            autoFocus
            type="text"
            margin="dense"
            name="tagName"
            label="Tag"
            value={formik.values.tagName}
            onChange={formik.handleChange}
            error={formik.touched.tagName && Boolean(formik.errors.tagName)}
            helperText={formik.touched.tagName && formik.errors.tagName}
            fullWidth
            size="small"
          />
          {/* Color Picker */}
          <TextField
            autoFocus
            type="color"
            margin="dense"
            name="color"
            label="Color"
            value={formik.values.color}
            onChange={formik.handleChange}
            error={formik.touched.color && Boolean(formik.errors.color)}
            helperText={formik.touched.color && formik.errors.color}
            fullWidth
            size="small"
          />
          <DialogActions>
            <Button
              className={classes.deleteButton}
              style={{ backgroundColor: "#ed342a", color: "white" }}
              onClick={handleClose}
              variant="contained"
            >
              <CloseIcon fontSize="small" className={classes.iconsStyle} />{" "}
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              <SaveIcon fontSize="small" className={classes.iconsStyle} /> Save
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default DialogForm;
