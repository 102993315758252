import React, { useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";

import EmailTemplateApi from "../../services/APIs/EmailTemplate.Api";
import { ConfirmDialog } from "../../components";

import dynamicStyle from "./styles";

const EmailTemplateList = () => {
  const classes = dynamicStyle();
  const navigate = useNavigate();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [templateID, setTemplateID] = useState(null);

  const { data: templateList, refetch } = useQuery("GET_TEMPLATES_LIST", () =>
    EmailTemplateApi.getTemplatesList()
  );

  const handleConfirmOpen = (id) => {
    setTemplateID(id);
    setConfirmOpen(true);
  };

  const handleDelete = async () => {
    const response = await EmailTemplateApi.deleteTemplate(templateID);
    if (response.success) {
      toast.success(response.message);
      setConfirmOpen(false);
      refetch();
    } else {
      toast.error("Template not deleted!");
    }
  };

  const handleUpdatNavigate = (id) => {
    navigate(`/template/update/${id}`);
  };

  console.log("Template ID ==", templateID);

  // Mui table columns ..
  const columns = [
    {
      name: "ID",
      lable: "ID",
    },
    {
      name: "templateName",
      label: "Name",
    },
    {
      name: "eventType",
      lable: "Event",
    },
    {
      name: "dealership",
      label: "Dealership",
    },
    {
      name: "department",
      label: "Department",
    },
    // Actions  ...
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <IconButton
                onClick={() => {
                  handleConfirmOpen(id);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleUpdatNavigate(id);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  // Mui table options ..
  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    selectableRows: "none",

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  //   Handle Back ..
  const handleBack = () => {
    navigate("/");
  };

  //   Add Template Navigation
  const addNewTemplate = () => {
    navigate("/create-template");
  };

  return (
    // Main Grid .
    <Grid>
      <Grid
        container
        justifyContent="space-between"
        className={classes.buttonGrid}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addNewTemplate}
          >
            ADD New Template
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.muiTableGrid}>
        <MUIDataTable
          title={"Templates List"}
          data={templateList}
          columns={columns}
          options={options}
        />
      </Grid>
      <Grid>
        {/* Delete Confirm Dialog  */}
        <ConfirmDialog
          title="Delete Template ?"
          dialogContext="Are you sure to delete template"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleDelete}
        />
      </Grid>
    </Grid>
  );
};

export default EmailTemplateList;
