import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";

import AuthApi from "../../services/APIs/Auth.Api";

import { SearchBox, Spinner } from "../../components";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
}));

const UserLogs = () => {
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(20);
  const [query, setQuery] = useState(null);
  const [search, setSearch] = useState(null);
  const [userLogs, setUserLogs] = useState([]);

  const { data, refetch: refetchLogs } = useQuery(
    ["GET_USER_LOGS", offset, query],
    () => getUserLogs()
  );

  const data1 = [
    { id: 1, details: "Lorem ipsum dolor sit amet" },
    { id: 2, details: "Consectetur adipiscing elit" },
    {
      id: 3,
      details:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
  ];

  const getUserLogs = async () => {
    if (search) {
      console.log("Search ..");
    } else {
      const response = await AuthApi.getUserLogs({
        offset: offset,
        limit: limit,
        queryVal: query,
      });
      setUserLogs((prevFiles) => {
        if (offset === 0) {
          return response;
        } else {
          return [...prevFiles, ...response];
        }
      });
    }
  };

  const handleSearch = async () => {
    if (query !== "") {
      const response = await AuthApi.getUserLogs({
        offset: offset,
        limit: limit,
        queryVal: query,
      });
      setUserLogs(response);
    }
  };

  // ====================
  // Handle Scroll End
  // ====================
  const handleScrollEnd = () => {
    setLoading(true);
    setTimeout(() => {
      setOffset((prev) => prev + 20);
      setLoading(false);
    }, 3000);
  };
  //   Handle Scroll  ...
  const handleScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        handleScrollEnd();
      }

      //When Scroll reached to top ...
      if (document.documentElement.scrollTop === 0) {
        setOffset(0);
      }
    } catch (error) {
      console.log("scroller catch error ===>", error);
    }
  };

  // Scroll Hook
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Grid>
      {/* Showing spinner on scroll */}
      <Spinner open={loading} loading={loading} size={60} />
      <Grid sx={{ mx: 1 }}>
        <SearchBox
          label="Search logs by name or details"
          query={query}
          setQuery={setQuery}
          setSearch={setSearch}
          onSearch={handleSearch}
        />
      </Grid>
      <Grid sx={{ my: 3 }}>
        {/* Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userLogs?.map((item) => (
                <StyledTableRow key={item?.ID}>
                  <TableCell>{item?.ID}</TableCell>
                  <TableCell>{item?.details}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default UserLogs;
