import React from "react";
import { Box, Button, Typography, Grid, Container } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import dynamicStyle from "./styles";

const PageNotFound = () => {
  const classes = dynamicStyle();
  return (
    <Box className={classes.box}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
            <Link className={classes.link} to={"/"}>
              <Button startIcon={<ArrowBackIcon />} variant="contained">
                Back Home
              </Button>
            </Link>
          </Grid>
          <Grid xs={6}>
            <img
              src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
              alt=""
              width={500}
              height={250}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PageNotFound;
