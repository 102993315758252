import React from "react";
import { Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";

import CustomTable from "../CustomTable/CustomTable";

import EmployeeApi from "../../../../services/APIs/Employee.Api";

const AllEmployee = (props) => {
  const { currentTabValue } = props;
  const { data, refetch } = useQuery(
    "GET_EMPLOYEES",
    () => EmployeeApi.getEmployeesByDealershipsAndDept(0) // 0 in param to get employee whose draft is 0
  );
  return (
    <Grid sx={{ margin: 2 }}>
      <CustomTable
        title="All Employees List"
        data={data}
        currentTabValue={currentTabValue}
      />
    </Grid>
  );
};

export default AllEmployee;
