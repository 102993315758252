import React from "react";
import { Grid } from "@mui/material";

import ReportForm from "../ReportForm/ReportForm";
import ReportTable from "../ReportTable/ReportTable";

const DirectEmployeeTab = (props) => {
  const {
    reportType,
    setReportType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleReset,
    data,
    hireFireEmployees,
  } = props;
  return (
    <>
      <ReportForm
        reportType={reportType}
        setReportType={setReportType}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handleReset={handleReset}
      />
      <Grid item sx={{ mt: 4 }}>
        <ReportTable
          data={data}
          title={reportType}
          hireFireEmployees={hireFireEmployees}
        />
      </Grid>
    </>
  );
};

export default DirectEmployeeTab;
