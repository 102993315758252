import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import dynamicStyle from "./styles";

const Filters = (props) => {
  const { open, setOpen, filters, setFilters, tags, users, onFilter } = props;
  const [tag, setTag] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [createdDateStart, setCreatedDateStart] = useState(null);
  const [createdDateEnd, setCreatedDateEnd] = useState(null);

  const classes = dynamicStyle();

  // Handle Submit Filters Values ..
  const handleSubmit = (event) => {
    event.preventDefault();
    onFilter &&
      onFilter({
        tag,
        createdBy,
        createdDateStart,
        createdDateEnd,
      });

    setOpen(false);
  };

  const onCreateDateStart = (event) => {
    event.preventDefault();
    setCreatedDateStart(event.target.value);
  };

  const onCreateDateEnd = (event) => {
    event.preventDefault();
    setCreatedDateEnd(event.target.value);
  };

  // Handle Clear Filters
  const handleClearFilters = (event) => {
    event.preventDefault();
    setTag(null);
    setCreatedBy(null);
    setCreatedDateStart(null);
    setCreatedDateEnd(null);
    onFilter &&
      onFilter({
        tag: null,
        createdBy: null,
        createdDateStart: null,
        createdDateEnd: null,
      });
    setOpen(false);
  };

  // Handle Created By
  const handleCreateBy = (e) => {
    e.preventDefault();
    setCreatedBy(e.target.value);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      style={{ zIndex: 1500 }}
      classes={{ paper: classes.drawer }}
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
    >
      <Grid container direction="column">
        <Card>
          <CardHeader
            avatar={
              <CloseIcon
                onClick={handleDrawerClose}
                style={{ cursor: "pointer" }}
              />
            }
            title="Close"
          />
        </Card>
        {/* Tag Grid */}
        <Grid
          container
          sx={{ marginTop: "13px" }}
          className={classes.textFieldGrid}
          spacing={2}
        >
          <Grid item xs={6} sm={12} lg={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={tags || []}
              value={tags?.find((option) => option.tagName === tag) || null}
              getOptionLabel={(option) => option.tagName}
              onChange={(even, value) => {
                if (value) {
                  setTag(value.tagName);
                } else {
                  setTag(null);
                }
              }}
              sx={{ width: 405 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  name="tag"
                  size="small"
                  label="Tag"
                />
              )}
            />
          </Grid>
        </Grid>
        {/* Create By Grid */}
        <Grid
          container
          sx={{ marginTop: "13px" }}
          className={classes.textFieldGrid}
          spacing={2}
        >
          <Grid item xs={6} sm={12} lg={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={users || []}
              value={
                users?.find((option) => option.userName === createdBy) || null
              }
              getOptionLabel={(option) => option.userName}
              onChange={(even, value) => {
                if (value) {
                  setCreatedBy(value.userName);
                } else {
                  setCreatedBy(null);
                }
              }}
              sx={{ width: 405 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  name="tag"
                  size="small"
                  label="Created By"
                />
              )}
            />
          </Grid>
        </Grid>

        {/* Created Date Start Grid */}
        <Grid
          container
          sx={{ marginTop: "13px" }}
          className={classes.textFieldGrid}
          spacing={2}
        >
          <Grid item xs={6} sm={12} lg={12}>
            <TextField
              name="start"
              label="Start"
              type="date"
              inputProps={{}}
              value={createdDateStart}
              onChange={onCreateDateStart}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>

        {/* Created Date End Grid */}
        <Grid
          container
          sx={{ marginTop: "13px" }}
          className={classes.textFieldGrid}
          spacing={2}
        >
          <Grid item xs={6} sm={12} lg={12}>
            <TextField
              name="start"
              label="End"
              type="date"
              inputProps={{}}
              value={createdDateEnd}
              onChange={onCreateDateEnd}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>

        {/* Action Section */}
        <Grid
          container
          className={classes.textFieldGrid}
          spacing={2}
          style={{ marginTop: "auto" }}
        >
          <Grid item lg={12} xs={12} sm={12} className={classes.buttonsGrid}>
            <Button
              style={{ width: "100%" }}
              variant="outlined"
              onClick={handleClearFilters}
            >
              Clear
            </Button>
          </Grid>
          <Grid item lg={12} xs={12} sm={12} className={classes.buttonsGrid}>
            <Button
              style={{ width: "100%" }}
              color="primary"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default Filters;
