import { api } from "../../utils";
import {
  CREATE_EMPLOYEE_LEAVE,
  GET_EMPLOYEE_LEAVES,
  GET_LEAVE_TYPES,
} from "../ApiConstants";

class LeaveOfAbsenceApi {
  static sharedInstance = new LeaveOfAbsenceApi();

  constructor() {
    if (LeaveOfAbsenceApi.sharedInstance != null) {
      return LeaveOfAbsenceApi.sharedInstance;
    }
  }

  async getLeaveTypes() {
    try {
      const response = await api.get(`${GET_LEAVE_TYPES}`);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  async createEmployeeLeave(body) {
    try {
      const response = await api.post(`${CREATE_EMPLOYEE_LEAVE}`, body);
      console.log(response);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async getEmployeeLeaves() {
    try {
      const response = await api.get(`${GET_EMPLOYEE_LEAVES}`);
      const { success, data } = response.data;
      console.log("Get leaves respoons ===", data);
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
}

export default LeaveOfAbsenceApi.sharedInstance;
