import axios from "axios";
import LocalStorage from "../../managers/LocalStorage";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV == "development"
      ? "https://dev.employee.camclarkgroup.com/api/"
      : "https://employee.camclarkgroup.com/api/",
});

// Adding auth Header Globaly ..
api.interceptors.request.use(function (config) {
  let jwt = sessionStorage.getItem("jwt");
  try {
    if (jwt) config.headers.Authorization = `Bearer ${jwt}`;

    return config;
  } catch (error) {
    console.log("Error in setting auth header globally !", error);
  }
});

api.interceptors.response.use(
  function (response) {
    // Any status code that lies within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    // Check if the status code is 400, 401, or 403
    if ([401, 403].includes(error.response?.status)) {
      // if (error?.response?.data?.message) alert(error.response.data.message);
      // Clear local and session storage
      localStorage.clear();
      sessionStorage.clear();

      // Refresh the page
      // window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default api;
