import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((them) => ({
  buttonGrid: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginTop: "15px",
  },
  titelGrid: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "70px",
  },
  title: {
    color: "blue",
    fontSize: "30",
    fontWeight: 20,
    textTransform: "uppercase",
  },
  fileListItem: {
    cursor: "pointer",
  },
}));

export default dynamicStyle;
