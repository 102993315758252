import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  EmployeeList,
  EmployeeForm,
  DraftList,
  SignIn,
  SignUp,
  Navbar,
  PageNotFound,
  TransferEmployeeList,
  EmployeeTransferForm,
  ViewTransferEmployee,
  UserList,
  AddUser,
  CreateFile,
  TagsManagement,
  EmailTemplateForm,
  EmailTemplateList,
  UserLogsList,
  SideDrawer,
  DashBoard,
  RemovalEmployeeList,
  EmployeeOnLeaveList,
  Reports,
} from "../views";

import LocalStorage from "../managers/LocalStorage";
import { useAuth } from "../hooks/useAuth";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Routing = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen);

  const { isLoggedIn } = useAuth();

  // Protected Routes ..
  const AuthenticatedRoute = ({ children }) => {
    const isAuthenticated = LocalStorage.isLoggedIn();
    const location = useLocation();
    if (!isAuthenticated) {
      return <Navigate to="/signin" state={{ from: location }} replace />;
    }

    return children;
  };

  // if user logged in then not navigate to login and singup page ..
  const Authenticated = ({ children }) => {
    const isAuthenticated = LocalStorage.isLoggedIn();
    const location = useLocation();
    if (isAuthenticated) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  };

  // Method for access routes based on the role ...
  const RoleAccess = ({ roles = [] }) => {
    const user = LocalStorage.getUser();
    return !roles.length || roles.includes(user?.role) ? (
      <Outlet />
    ) : (
      <Navigate to="/unauthorized" replace />
    );
  };

  // Method for access routes based on the role ...
  const hasEmailTemplateAccess = () => {
    const user = LocalStorage.getUser();
    if (
      [
        "hahmed@camclarkgroup.com",
        "ali@camclarkgroup.com",
        "nwirth@camclarkgroup.com",
        "pvaughn@camclarkgroup.com",
        "lvass@camclarkgroup.com",
        "bguday@camclarkgroup.com",
        "lbriglio@camclarkgroup.com",
      ].includes(user?.email)
    )
      return true;
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Check user isLogged in or not for hide and show side bar
  React.useEffect(() => {
    if (!isLoggedIn) {
      setOpen(false);
    } else {
      setOpen(!isSmallScreen);
    }
  }, [isLoggedIn]);

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Navbar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            setOpen={setOpen}
          />
        </AppBar>
        <SideDrawer
          setOpen={setOpen}
          open={open}
          handleDrawerClose={handleDrawerClose}
          theme={theme}
          isSmallScreen={false}
        />
        {/* Main Section  */}
        <Main open={open} style={{ paddingTop: 80, marginLeft: 0 }}>
          <Routes>
            <Route
              path="/signin"
              element={
                <Authenticated>
                  <SignIn />
                </Authenticated>
              }
            />
            <Route path="/signup" element={<SignUp />} />
            {/* Admin */}
            <Route element={<RoleAccess roles={["Admin"]} />}>
              <Route path="/users" element={<UserList />} />
            </Route>
            <Route element={<RoleAccess roles={["Admin"]} />}>
              <Route path="/add-user" element={<AddUser />} />
            </Route>
            <Route element={<RoleAccess roles={["Admin"]} />}>
              <Route path="/user/update/:id" element={<AddUser />} />
            </Route>
            <Route element={<RoleAccess roles={["Admin"]} />}>
              <Route path="/logs" element={<UserLogsList />} />
            </Route>
            <Route element={<RoleAccess roles={["Admin"]} />}>
              <Route path="/tags-management" element={<TagsManagement />} />
            </Route>
            {/* Email Template Routes Route */}

            {hasEmailTemplateAccess() ? (
              <>
                <Route element={<RoleAccess roles={["Admin"]} />}>
                  <Route
                    path="/create-template"
                    element={<EmailTemplateForm />}
                  />
                </Route>
                <Route element={<RoleAccess roles={["Admin"]} />}>
                  <Route
                    path="/email-templates"
                    element={<EmailTemplateList />}
                  />
                </Route>
                <Route element={<RoleAccess roles={["Admin"]} />}>
                  <Route
                    path="/template/update/:id"
                    element={<EmailTemplateForm />}
                  />
                </Route>
              </>
            ) : (
              <></>
            )}
            {/* Dashboard Route */}
            <Route
              path="/"
              element={
                <AuthenticatedRoute>
                  <DashBoard />
                </AuthenticatedRoute>
              }
            />
            {/* Protected Routes */}
            <Route
              path="/employees"
              element={
                <AuthenticatedRoute>
                  <EmployeeList />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/transfer-employee-list"
              element={
                <AuthenticatedRoute>
                  <ViewTransferEmployee />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/transfer-employee"
              element={
                <AuthenticatedRoute>
                  <TransferEmployeeList />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/transfer-employee/:id"
              element={
                <AuthenticatedRoute>
                  <EmployeeTransferForm />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/draft"
              element={
                <AuthenticatedRoute>
                  <DraftList />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/create"
              element={
                <AuthenticatedRoute>
                  <EmployeeForm />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/update/:id"
              element={
                <AuthenticatedRoute>
                  <EmployeeForm />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/file/:id"
              element={
                <AuthenticatedRoute>
                  <CreateFile />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/removals"
              element={
                <AuthenticatedRoute>
                  <RemovalEmployeeList />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="leaves"
              element={
                <AuthenticatedRoute>
                  <EmployeeOnLeaveList />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="reports"
              element={
                <AuthenticatedRoute>
                  <Reports />
                </AuthenticatedRoute>
              }
            />

            <Route path="*" element={<PageNotFound />} />
            <Route />
          </Routes>
        </Main>
      </Box>
    </Router>
  );
};

export default Routing;
