import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: "#1976d2",
    color: "white",
    textTransform: "uppercase",
    justifyContent: "space-between",
    display: "flex",
    height: 70,
  },

  dialogContent: {
    marginTop: "10px",
  },
  deleteButton: {
    backgroundColor: "#ca0014",
    color: "white",
  },
  iconsStyle: {
    marginRight: "10px",
  },
}));
export default dynamicStyle;
