import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  buttonGrid: {
    marginTop: "5px",
  },
  muiTableGrid: {
    marginTop: 25,
  },
}));

export default dynamicStyle;
