import React from "react";
import { Grid } from "@mui/material";

import EmployeeCard from "../EmployeeCard/EmployeeCard";
import CustomCard from "../CustomCard/CustomCard";

import DashBoardApi from "../../../../services/APIs/DashBoard.Api";

import { useQuery } from "react-query";

const AdminTab = () => {
  // Get employee analytics ..
  const { data } = useQuery("GET_EMPLOYEE_ANALYTICS", () =>
    DashBoardApi.getEmployeeAnalytics()
  );

  // Transfer employee analytics ..
  const { data: transferEmployeeAnalytics } = useQuery(
    "GET_TRANSFER_EMPLOYEE_ANALYTICS",
    () => DashBoardApi.getTransferEmployeeAnalytics()
  );

  // Upcoming annivarseries ..
  const { data: employeeAnniversaries } = useQuery(
    "GET_EMPLOYEE_ANNIVERSARIES",
    () => DashBoardApi.getEmpolyeeAnniversaries()
  );

  // Upcoming removal employees requrests ..
  const { data: upcomingRemovalEmployees } = useQuery(
    "GET_EMPLOYEE_REMOVAL_EMPLOYEES",
    () => DashBoardApi.getRemovalEmployeeAnalytics()
  );

  // Upcoming employees leaves requests  ..
  const { data: upcomingEmployeesLeaves } = useQuery(
    "GET_EMPLOYEES_UPCOMING_LEAVES",
    () => DashBoardApi.getEmployeesLeavesAnalytics()
  );

  // Upcoming employee birthdays ..
  let employeeBirthdayArray =
    data && data.length > 0
      ? JSON.parse(data[0]?.upcomingBirthdaysEmployees) || ""
      : "";
  // Upcoming employee transfer request ..
  let employeeTransferArray =
    transferEmployeeAnalytics && transferEmployeeAnalytics.length > 0
      ? JSON.parse(transferEmployeeAnalytics[0]?.transferRequestEmployee) || ""
      : "";

  // Employee annivasaries data for displayin on the card ..
  const anniverSariesData =
    employeeAnniversaries &&
    employeeAnniversaries?.map((employee) => ({
      "Employee Name": employee.name,
      "Anniversary Date": employee.anniversaryDate,
      Years: employee.workOfYears,
    }));

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item md={6} xs={12}>
        <EmployeeCard
          title="Employees"
          value={data && data[0]?.totalEmployee}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <EmployeeCard
          title="Draft Employees"
          value={data && data[0]?.draftEmployee}
        />
      </Grid>
      {/* Upcoming birthdays  */}
      <CustomCard
        title="Upcoming Birthdays"
        data={employeeBirthdayArray}
        columns={["Employee Name", "Birthday"]}
      />
      {/* Upcoming Transfer Request */}
      <CustomCard
        title="Transfer Requests"
        data={employeeTransferArray}
        columns={["Employee Name", "Transfer Date"]}
      />
      {/* Upcoming employee aniiversaries */}
      <CustomCard
        title="Upcoming Anniversaries"
        data={anniverSariesData}
        columns={["Employee Name", "Anniversary Date", "Years"]}
      />
      {/* upcoming  employees removals */}
      <CustomCard
        title="Upcoming removals"
        data={upcomingRemovalEmployees}
        columns={["Employee Name", "Removal Date"]}
      />
      {/* upcoming employees leaves request  */}
      <CustomCard
        title="Upcoming Employees Leaves"
        data={upcomingEmployeesLeaves}
        columns={["Employee Name", "Leave Date", "Return Date"]}
      />
    </Grid>
  );
};

export default AdminTab;
