// authConfig.js
import { PublicClientApplication } from "@azure/msal-browser";

const clientID = "ac5c3b7c-b930-47df-b623-69a8beb83aff";
const tenantID = "53cce716-d0b1-4cef-b9ab-98a57050ba43";
// const redirectURL = "http://localhost:3000";
const redirectURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://employee.camclarkgroup.com";

const msalConfig = {
  auth: {
    clientId: clientID,
    authority: `https://login.microsoftonline.com/${tenantID}`,
    redirectUri: redirectURL,
    knownAuthorities: [`login.microsoftonline.com`], // Typically only needed for Azure AD B2C or other special cases
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set to true if you have issues with session being lost after refresh in some browsers
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);
