import React, { useEffect } from "react";
import { Grid, Typography, Tab, Tabs, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { AllEmployee, DirectEmployee } from "./components";

const EmployeeList = () => {
  // Tab state ..
  const [tabValue, setTabValue] = React.useState(0);

  const location = useLocation();
  const prevTab = location?.state?.previousTab;

  // set previousTab after create and update employee
  useEffect(() => {
    if (prevTab !== undefined) {
      setTabValue(prevTab);
    }
  }, []);

  // Handle tab change ..
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {/* Employees list tab */}
      <Grid container spacing={2}>
        <Grid md={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              allowScrollButtonsMobile
              variant="scrollable"
            >
              <Tab label="Direct Employees" {...a11yProps(0)} />
              <Tab label="All Employees" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {/* Direct employees tab */}
          <CustomTabPanel value={tabValue} index={0}>
            <DirectEmployee currentTabValue={tabValue} />
          </CustomTabPanel>
          {/* All employees tab */}
          <CustomTabPanel value={tabValue} index={1}>
            <AllEmployee currentTabValue={tabValue} />
          </CustomTabPanel>
        </Grid>
      </Grid>
    </>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default EmployeeList;
