import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((them) => ({
  buttonGrid: {
    marginTop: 10,
  },
  buttonContainer: {
    justifyContent: "flex-end",
    marginTop: "15px",
  },
}));

export default dynamicStyle;
