import React from "react";
import { Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";

import CustomTable from "../CustomTable/CustomTable";

import EmployeeApi from "../../../../services/APIs/Employee.Api";

const DirectEmployee = (props) => {
  const { currentTabValue } = props;
  // get managers employees ..
  const { data, refetch } = useQuery("GET_MANAGER_EMPLOYEES", () =>
    EmployeeApi.getEmployeeByManagers()
  );

  return (
    <Grid sx={{ margin: 2 }}>
      <CustomTable
        title="Direct Employees List"
        data={data}
        currentTabValue={currentTabValue}
      />
    </Grid>
  );
};

export default DirectEmployee;
